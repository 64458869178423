import React, { useState } from "react"
//import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, } from "reactstrap"
//import whatsapp from "../../../assets/images/whatsapp.png"


const NotificationDropdown = props => {
  const user = JSON.parse(localStorage.getItem("user"));
  const groups = String(user.groups);
 /* const location = useLocation();
  const history = useHistory();
  const URL = process.env.REACT_APP_API_URL.split('/');
  const chatSocketManagementRef = useRef(null);


  let socketUrlManagement = `wss://${URL[2]}/ws/en_gestion_conversation/`;
  useEffect(() => {
    if (chatSocketManagementRef.current) {
      return;
    }
    const chatSocketManagement = new WebSocket(socketUrlManagement);
    chatSocketManagementRef.current = chatSocketManagement;

    chatSocketManagement.onopen = () => {
      console.log("Gestiones conectado");
    };

    chatSocketManagement.onclose = () => {
      console.log("Gestiones desconectado");
    };

    chatSocketManagement.onmessage = async (event) => {
      const updateData = JSON.parse(event.data);
      const { ultimo_message, contacto, id, type, status } = updateData.conversation_data;
      if (ultimo_message[2] === false && ultimo_message[3] === 'received' && (status === 'en_gestion' || status === 'nuevo')) {
        showNotification(
          contacto ? contacto.contact_name : "Contacto Desconocido",
          ultimo_message[0] === 'text' ? `${ultimo_message[1]}` : "",
          id,
          contacto && contacto.contact_id,
          type,
        );
      }
    };

    return () => {
      chatSocketManagement.close();
      chatSocketManagementRef.current = null;
    };
            // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketUrlManagement]);

  const showNotification = (title, message, selectedChatId, contactId, type) => {
    if (location.pathname !== "/chat" || document.hidden ) {
      if (Notification.permission === "granted") {
        const notification = new Notification(title, {
          body: message,
          icon: whatsapp
        });

       const audio = new Audio('/ding.mp3');
        audio.play().catch(error => {
          console.error("Error al reproducir el sonido de notificación:", error);
        });

        notification.onclick = () => {
          window.focus();
          history.push({
            pathname: '/chat',
            state: { selectedChatId, contactId, type }
          });
        };
      }
    }
  };*/
  const [menu, setMenu] = useState(false)

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="mdi mdi-bell" />
          {/* <span className="badge bg-danger rounded-pill"> {users.length} </span> */}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">Notificaciones</h6>
              </Col>
              {groups.includes("SuperAdmin") ||  groups.includes("Administrador") || groups.includes("Supervisor") ? (
                <div className="col-auto">
                  <a href="users/user_por_vincular" className="small">
                    Ver todas
                  </a>
                </div>
              ) : null}
            </Row>
          </div>
          {/*    <div style={{ height: "auto" }}>
            <Link to="#" className="text-reset notification-item d-block">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-danger rounded-circle font-size-16">
                    <i className=" fas fa-user-clock " />
                  </span>
                </div>
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">
                    Notificación 
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      El Usuario  está solicitando vinculación
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />{" "}
                      Hace 3 min
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>*/}

        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default NotificationDropdown
