import React from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import "./Reports.css"; 

// Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

const Reports = () => {

  const reportCategories = [
    {
      title: "Reportes de WhatsApp",
      reports: [
        { name: "Reporte de WhatsApp", path: "/reports/whatsapp" },
        { name: "Reporte de ingresados al bot", path: "/report_for_bot" },
        { name: "Reporte de gestiones por hora", path: "/report_for_hour" },
        { name: "Reporte de gestiones por tiempo de atención", path: "/report_for_time" },
      ],
    },
    {
      title: "Reportes de Llamadas",
      reports: [
        { name: "Informe de Campaña", path: "/reports/campaign-report" },
        { name: "Llamadas ingresadas por hora", path: "/reports/calls-per-hour" },
        { name: "Tiempo promedio conversación llamadas salientes", path: "/reports/outgoing-call-duration" },
        { name: "Tiempo promedio conversación llamadas entrantes", path: "/reports/incoming-call-duration" },
        { name: "Tiempo promedio primera atención", path: "/reports/first-attention-time" },
      ],
    },
    {
      title: "Reportes de Leads",
      reports: [
        { name: "Reporte de Leads", path: "/reports/leads" },
        { name: "Leads por hora", path: "/leads/report" },
        { name: "Tiempo promedio de contacto", path: "/reports/average-contact-time" },
      ],
    },
    {
      title: "Reportes por Tipificación",
      reports: [
        { name: "Reporte por Tipificaciones", path: "/report_type" },
      ],
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Reportes</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="TrueContact" breadcrumbItem="Reportes" />
          <Row className="justify-content-center">
            {reportCategories.map((category, index) => (
              <Col md={4} key={index} className="mb-4">
                <Card className="report-card">
                  <CardBody>
                    <h4 className="report-title">{category.title}</h4>
                    <ul className="report-list">
                      {category.reports.map((report, idx) => (
                        <li key={idx} className="report-item">
                          <span>{report.name}</span>
                          <Link to={report.path} className="report-link">Ver</Link>
                        </li>
                      ))}
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Reports;
