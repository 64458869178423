import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  Button,
  Modal,
} from "reactstrap";
import whatsapp from "../../../assets/images/whatsapp.png";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { leadsFilter } from "../../../store/actions";
import Pagination from "../../../components/Common/Pagination";
import Loader from "../../../components/Common/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ManagementManual from "../../Whatsapp/components/ManagementManual";
import ModalManagementDetails from "./ModalManagementDetails";
import TimeLine from "../../Leads/components/TimeLine";
import { getCallStart } from "../../../helpers/backend";


function Leads(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [manualOpen, setManualOpen] = useState(false);
  const [detallesLead, setDetallesLead] = useState("");
  const [detallesOpen, setDetallesOpen] = useState(false);
  const [detallesModal, setDetallesModal] = useState(false);
  const [modalTimeLine, setModalTimeLine] = useState(false);
  const [gestionLead, setGestionLead] = useState(null);
  const [GestionDetallada, setGestionDetallada] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [leadId, setLeadId] = useState(null);

  const handleDetallesModal = () => {
    setDetallesModal(false);
  };
  const handleCallStart = async (number) => {
    console.log(number);
    const response = await getCallStart({
      number: number,
      extension: "1203",
    });

    console.log(response);
  };
  useEffect(() => {
    dispatch(
      leadsFilter(
        `?tipo_lead=event&fecha_inicio=&fecha_fin=&estado=PENDIENTE`
      )
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const StatusBadge = ({ estado }) => {
    const estadoConfig = {
      FINALIZADO: { text: "Finalizado", className: "bg-success" },
      PENDIENTE: { text: "Pendiente", className: "bg-danger" },
      SIN_ASIGNAR: { text: "Sin Asignar", className: "bg-warning" },
      EN_PROCESO: {
        text: "En Proceso",
        className: "",
        style: { background: "rgb(111, 66, 193)" },
      },
    };
    const config = estadoConfig[estado] || {};
    return (
      <span className={`badge ${config.className}`} style={config.style}>
        {config.text}
      </span>
    );
  };

  const params = {
    count: props.leads.count,
    next: props.leads.next,
    previous: props.leads.previous,
    results: props.leads.results,
  };

  return (
    <div>
      <Container fluid>
        {props.loading ? (
          <Loader />
        ) : (
          <div>
            <Modal
              isOpen={detallesOpen}
              toggle={() => setDetallesOpen(false)}
              centered={true}
            >
              <div className="modal-header">
                <h4 className="mt-2">Detalles Adicionales</h4>
                <button
                  type="button"
                  onClick={() => setDetallesOpen(false)}
                  className="btn-close btn-sm"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="p-3">
                <p style={{ whiteSpace: "pre-wrap" }}>{detallesLead}</p>
              </div>

              <Row className="mt-3 p-2">
                <Col className="d-flex justify-content-end">
                  <Button
                    className="text-dark btn btn-secondary"
                    onClick={() => setDetallesOpen(false)}
                  >
                    Cerrar
                  </Button>
                </Col>
              </Row>
            </Modal>

           {manualOpen && (
              <ManagementManual
                isLead={true}
                isOpen={manualOpen}
                onClose={() => setManualOpen(false)}
                contactId={contactId}
                leadId={leadId}
              />
            )}
            {detallesModal && (
              <ModalManagementDetails
                isOpen={detallesModal}
                onCloseClick={handleDetallesModal}
                GestionDetallada={GestionDetallada}
              />
            )}
            {modalTimeLine && (
              <TimeLine
                isOpen={modalTimeLine}
                onClose={() => setModalTimeLine(false)}
                GestionLead={gestionLead}
              />
            )}
            <Card className="mx-auto">
               {(params.count === 0 ? (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                ) : (
                  <CardBody>
                    <div className="table-responsive">
                      <h4 className="text-dark">Leads</h4>
                      <Table
                        className="table mb-4 text-center"
                        hover
                        responsive
                      >
                        <thead className="table-dark">
                          <tr>
                            <th>#</th>
                            <th>Origen</th>
                            <th>Creación</th>
                            <th>Nombre</th>
                            <th>Estado</th>
                            <th>Teléfono</th>
                            <th>Email</th>
                            <th>Campaña</th>
                            <th>Agente</th>
                            <th></th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody className="align-middle">
                          {params.results &&
                            params.results.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {item.gestion_leads &&
                                  item.gestion_leads.length > 0 ? (
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        setModalTimeLine(true);
                                        setGestionLead(item.gestion_leads);
                                      }}
                                    >
                                      {" "}
                                      <b> {index + 1}</b>
                                    </Link>
                                  ) : (
                                    <b> {index + 1}</b>
                                  )}
                                </td>
                                <td>{item.source}</td>
                                <td>{item.created_date}</td>
                                <td>
                                  {item.contact && item.contact.first_name}
                                </td>
                                <td>
                                  <StatusBadge estado={item.estado} />
                                  {item.estado === "FINALIZADO" &&
                                  item.gestion_contacto ? (
                                    <Link to="#">
                                      <span
                                        className="fas fa-eye fa-md mx-1 "
                                        onClick={() => {
                                          setDetallesModal(true);
                                          setGestionDetallada(
                                            item.gestion_contacto
                                          );
                                        }}
                                      ></span>
                                    </Link>
                                  ) : null}
                                </td>
                                <td>
                                  {item.contact &&
                                    item.contact.phone[0]?.telefono}
                                </td>
                                <td>
                                  {item.contact && item.contact.email[0]?.email}
                                </td>
                                <td>{item.campaign}</td>
                                <td>
                                  {item.agent && item.agent[0]
                                    ? item.agent[0][0]
                                    : null}
                                </td>
                                <td>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setDetallesLead(item.metadata);
                                      setDetallesOpen(true);
                                    }}
                                  >
                                    {" "}
                                    Ver Detalles
                                  </Link>
                                </td>
                                <td>
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      window.focus();
                                      history.push("/temp"); // Ir a una ruta temporal
                                      setTimeout(() => {
                                        history.replace({
                                          pathname: "/chat",
                                          state: {
                                            selectedChatId: item.contact?.phone[0]?.telefono,
                                            contactId: item.contact?.id,
                                            type: "WhatsApp",
                                            is_lead: true,
                                            leadId: item.id,
                                          },
                                        });
                                      }, 50); // Regresar a "/chat" rápidamente
                                    }}

                                  >
                                    <img
                                      className="chat-item__img"
                                      src={whatsapp}
                                      alt="Avatar"
                                    />
                                  </span>{" "}
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setManualOpen(true);
                                      setContactId(
                                        item.contact ? item.contact.id : null
                                      );
                                      setLeadId(item.id);
                                      handleCallStart(
                                        item.contact &&
                                        item.contact.phone[0]?.telefono
                                      );
                                    }}
                                  >
                                    <span
                                      className="fa fa-phone fa-md align-middle"
                                      style={{ fontSize: "20px" }}
                                    ></span>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl="leads/leads/"
                      filterFunction={leadsFilter}
                    />
                  </CardBody>
                ))}
            </Card>
 
          </div>
        )}
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { error, loading, leads } = state.Leads;
  return { error, loading, leads };
};

export default withRouter(connect(mapStateToProps)(Leads));
