import React, { useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import { 
  Card,
  CardBody,
  Col,
  Container,
  Row,Nav, NavItem, NavLink, TabContent, TabPane, Table
 } from "reactstrap"
 import classnames from 'classnames';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"
import { getCasosRead } from "../../helpers/backend";
import { useParams } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from "./CasosDetails.module.scss"
import Timeline from "./TimeLine";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const CasosDetails = (props) => {
    const id = useParams().id
    const URL = process.env.REACT_APP_API_URL;
    const url = URL.split('/api')
    const URL1 = process.env.REACT_APP_API_URL.split("/api").join("");
    const [activeTab, setActiveTab] = useState('1');

const  [caso, setCaso] = useState ({});
console.log(caso)
const getCasoData = async () => {
    const response = await getCasosRead(id);
    const data = response.data;
    setCaso(data)
}
const toggle = tab => {
  if (activeTab !== tab) setActiveTab(tab);
}
const abrirImagenEnNuevaPestana = (imageUrl) => {
  window.open(imageUrl, '_blank');
};
  useEffect(() => {
    getCasoData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  function Status({ estado }) {
    const estados = {    
      EN_PAUSA: { className: "bg-info", text: "En Pausa" },
      PENDIENTE: { className: "bg-danger", text: "Pendiente" },
      SIN_ASIGNAR: { className: "bg-warning", text: "Sin Asignar" },
      EN_PROCESO: { className: "bg-pink", text: "En Proceso" },
      FINALIZADO: { className: "bg-success", text: "Finalizado" },
      ASIGNADO: { className: "bg-primary  ", text: "Asignado" }
    };
  
    const estadoActual = estados[estado];
  
    return (
      <div className={`badge  ${estadoActual.className} font-size-18`}>
        <strong>{estadoActual.text}</strong>
      </div>
    );
  }
  const fotos = caso.images

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Detalles del Caso </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Detalles del Caso" />

          {props.loading ? (
            <Loader />
          ) : (
            <div className="d-flex justify-content-center">              
                <Card className={styles.ticket} style={{border: '0px'}}>
                    {caso.estado?
                    <div style={{zIndex: '999'}}>
                    <b className="position-absolute top-0 end-0 m-2" ><Status estado={caso.estado} /></b>
                    </div> :null}
                    <div className="position-absolute top-0 start-0 m-2"  style={{zIndex: '999'}}>
                    <Link className="btn btn-secondary btn-sm text-dark" to="/casos">Volver</Link>
                    </div>
                    
                    <div className={styles["ticket-header"]}>
                      <h1><b>{caso.name}</b> </h1>
                      {caso.total_duracion ?
                      <p className="d-flex justify-content-center"><b>Tiempo de Finalizacion: </b> {caso.total_duracion}</p>
                      : null}
                    </div>
                    <div className={styles["ticket-body"]}>
                    <Card style={{width: '100%', height:'100%', border: '0'}}>
                    <Nav tabs justified className="nav-tabs-custom"  role="tablist">

                      <NavItem>
                          <NavLink
                              to="#"
                              className={`p-2 ${classnames({ active: activeTab === '1' })}`}
                              onClick={() => { toggle('1'); }}
                              style={{ cursor: 'pointer'}}>
                              <i className="fas fa-ticket-alt fa-lg"></i>
                              <span className="d-none d-sm-block">Detalles del Caso</span>
                          </NavLink>
                      </NavItem>                
                      <NavItem >
                          <NavLink
                              to="#"
                              className={`p-2 ${classnames({ active: activeTab === '2' })}`}
                              style={{ cursor: 'pointer'}}
                              onClick={() => { toggle('2'); }}>
                              <i className="fas fa-tasks fa-lg "></i>
                              <span className="d-none d-sm-block">Gestiones del Caso</span>
                          </NavLink>
                      </NavItem>
                      <NavItem >
                          <NavLink
                              to="#"
                              className={`p-2 ${classnames({ active: activeTab === '3' })}`}
                              style={{ cursor: 'pointer'}}
                              onClick={() => { toggle('3'); }}>
                              <i className="fas fa-file-alt fa-lg"></i>
                              <span className="d-none d-sm-block">Informe</span>
                          </NavLink>
                      </NavItem>
                      <NavItem >
                          <NavLink
                              to="#"
                              className={`p-2 ${classnames({ active: activeTab === '4' })}`}
                              style={{ cursor: 'pointer'}}
                              onClick={() => { toggle('4'); }}>
                             <i className="fas fa-box fa-lg"></i>
                              <span className="d-none d-sm-block">Materiales</span>
                          </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent className="p-4" activeTab={activeTab}>  
                        <TabPane id="1" role="tabpanel" tabId="1">
                        <Row style={{ lineHeight: "0.5rem"}}>
                            <Col lg={6}>
                            <p><b>Fecha de Creación: </b> {caso.created_date}</p>
                            <p><b>Tipo de Servicio: </b> {caso.tipo_servicio}</p>
                            <p><b>Descripción: </b> {caso.descripcion}</p>
                            <p><b>Cliente(s): </b> 
                            {caso.cliente && caso.cliente.map((cliente, index) => (
                                <span key={index} className="badge bg-dark mr-2">
                                    {cliente[0] || cliente}
                                </span>
                            ))}
                        </p>
                            </Col>
                            <Col lg={6} >
                            {caso.tipo_servicio === 'DESPLAZAMIENTO' ? <>
                            <p><b>Ubicación: </b> {caso.direccion_1}</p>
                            <p><b>Destino: </b> {caso.direccion_2}</p> </>: null}
                            <p><b>Observaciones: </b> {caso.observaciones}</p>
                            <p><b>Agente(s): </b> 
                                {caso.contacto && caso.contacto.map((contacto, index) => (
                                    <span key={index} className="badge bg-dark mx-1 mt-1">
                                        {contacto[0] || contacto}
                                    </span>
                                ))}
                            </p>

                            </Col>
                        </Row>
                        {caso.images && caso.images.length !== 0 ? <>  
                        <Row className="d-flex justify-content-center">
                        <Col lg={10}>
                            <p><b>Fotos:</b> </p>
                        <Card>
                            <CardBody>
                            <div>
                                <Carousel infiniteLoop showArrows>
                                {fotos  && fotos.map((item) => (
                                <div key={item.id} onClick={() => abrirImagenEnNuevaPestana(url[0] + item.image)}>
                                  <img src={url[0] + item.image} alt={item.image} />
                                </div>
                                ))}
                                </Carousel>
                            </div>
                            </CardBody>                        
                        </Card>
                        </Col>  
                        </Row>  </> : 
                           <h6 className="mt-4"><b>No se han cargado fotos</b> </h6>
                        } 
                        </TabPane>                  
                        <TabPane id="2" role="tabpanel" tabId="2">
                          <Timeline gestiones={caso.gestiones} />
                        </TabPane>  

                        <TabPane id="3" role="tabpanel" tabId="3">
                        <Row className="mb-4">
  <Col lg={10} className="mx-auto">
    <Card>
      <CardBody>
        <h5 className="mb-4 text-center">Detalles del Informe</h5>
        <Row>
          <Col lg={12}>
          <p><b>Descripción:</b></p>
<div 
  style={{
    maxHeight: "150px", 
    overflowY: "auto", 
    backgroundColor: "#f8f9fa", 
    padding: "10px", 
    border: "1px solid #dee2e6", 
    borderRadius: "5px"
  }}
>
  {caso.informe?.[0]?.descripcion || "Sin descripción"}
</div>
            <p><b>Persona que recibe:</b> {caso.informe?.[0]?.persona_recibe || "Sin descripción"}</p>
            <p><b>Cargo:</b> {caso.informe?.[0]?.cargo || "Sin descripción"}</p>
            <p><b>Observaciones:</b></p>
<div 
  style={{
    maxHeight: "150px", 
    overflowY: "auto", 
    backgroundColor: "#f8f9fa", 
    padding: "10px", 
    border: "1px solid #dee2e6", 
    borderRadius: "5px"
  }}
>
  {caso.informe?.[0]?.observaciones || "Sin observaciones"}
</div>
          </Col>
        </Row>
        <hr className="my-4" />
        <Row className="text-center">
          <Col>
            <p><b>Firma:</b></p>
            {caso.informe?.[0]?.firma ? (
              <div
                style={{ cursor: "pointer", display: "inline-block" }}
                onClick={() => abrirImagenEnNuevaPestana(`${URL1}${caso.informe?.[0]?.firma}`)}
              >
                <img
                  style={{ width: "30%", height: "auto", border: "1px solid #ddd", borderRadius: "8px", padding: "5px" }}
                  src={`${URL1}${caso.informe?.[0]?.firma}`}
                  alt="Firma"
                />
              </div>
            ) : (
              <p className="text-muted">Sin firma disponible</p>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
</Row>

                        </TabPane>
                        <TabPane id="4" role="tabpanel" tabId="4">
  {Array.isArray(caso?.materiales) && caso.materiales.length > 0 ? (
    <Table className="table table-sm align-middle table-centered table-nowrap mb-3 mt-1">
      <thead className="table-dark align-middle">
        <tr>
          <th>Descripcion</th>
          <th>Cantidad</th>
          <th>Observaciones</th>
        </tr>
      </thead>
      <tbody>
        {caso.materiales.map((material, index) => (
          <tr key={material.id || index}>
            <td>{material.descripcion}</td>
            <td>{material.cantidad}</td>
            <td>{material.observaciones}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <p>No hay materiales disponibles.</p>
  )}
</TabPane>

                    </TabContent> 
                    </Card>   
                    </div>
                </Card>
            </div>

          )}

        </Container>
      </div>
    </React.Fragment>
  )
}




export default CasosDetails;

