import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { 
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Input,
  Button,
  InputGroup,
  Label,
  
 } from "reactstrap"

import { IoCheckmarkDoneSharp,IoPlaySharp,IoPauseSharp, IoStopSharp } from 'react-icons/io5';
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { withRouter, Link, useHistory} from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"
import Pagination from "../../components/Common/Pagination_ansync"
import styles from "../Contacts/Contacts.module.scss"
import { getAgent, getCasosEstados, getCasosEstadosFilter, getCasosFilter, getCasosList, postCasosStatusChange } from "../../helpers/backend";

import ModalMateriales from "./ModalMateriales";
import ModalInforme from "./ModalInforme";

const Casos = (props) => {
  
  const history = useHistory()
 
  const [botonActivo, setBotonActivo] = useState("Todas");
  const [modalMateriales, setModalMateriales] = useState(false);
  const [modalInforme, setModalInforme] = useState(false);
  const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

 
  const  [agent, setAgent] = useState ({});
  const getAgentData = async () => {
    const response = await getAgent();
    const data = response.data;
    setAgent(data)
  }
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [actionAlert, setActionAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [action, setAction] = useState("");
  const [ticket, setTicket] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [coordenadas, setCoordenadas] = useState("");
  const [ticketId, setTicketId] = useState(null);

  const [casoId, setCasoId] = useState(null);
  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
      let url;
        if (botonActivo === 'Todas') {
          url = `?search=${search}`;
        } else {
          url = `casos_por_estado/?estado=${botonActivo}&search=${search}`;
        }
        const response = await getCasosFilter (url);
        setParams({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: response.data.results,
        });
    setLoading(false);
  };

  const resetSearch = async() => {
    setLoading(true);
    let url;
    if (botonActivo === 'Todas') {
      url = `?search=`;
    } else {
      url = `casos/casos/casos_por_estado/?estado=${botonActivo}&search=`;
    }
    const response = await getCasosFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setSearch("");
    setLoading(false);
  };

  const getCasosData = async () => {
    setLoading(true);
    const response = await getCasosList();
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };
  const getCasosPorEstados = async (estado) => {
    setLoading(true);
    const response = await getCasosEstados(estado);
    console.log(response);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
    setBotonActivo(estado);
  };
  const getFilterData = async (url) => {
    setLoading(true);
    const response = await getCasosFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };
  const getFilterCasosData = async (url) => {
    setLoading(true);
    const response = await getCasosEstadosFilter(botonActivo, url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };

  const handleAction = (action, ticket, id) => {
    setAction(action)
    setTicket(ticket)
    setTicketId(id)
    setActionAlert(true)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
          setCoordenadas ( latitude + " " + longitude )
      });
    } else {
      console.log("La geolocalización no está disponible en este navegador.");
    }
  }

  const handleMateriales = (id) => {
    setCasoId(id)
    setModalMateriales(true)
  }
  const actionSuccess = async () =>{
   const response = await postCasosStatusChange(ticketId, {
      accion:action,
      observaciones:observaciones,
      coordenadas: coordenadas
    })
      if(response.status === 200) {
        setShowAlertSuccess(true)
      }
      else {
        setErrorAlert(true)
      }
    setActionAlert(false)
  }




  useEffect(() => {
    getCasosData()
    getAgentData() 

  }, [])

  function Status({ estado }) {
    const estados = {    
      EN_PAUSA: { className: "bg-info", text: "En Pausa" },
      PENDIENTE: { className: "bg-danger", text: "Pendiente" },
      SIN_ASIGNAR: { className: "bg-warning ", text: "Sin Asignar" },
      EN_PROCESO: { background: "#6f42c1 ", text: "En Proceso" },
      FINALIZADO: { className: "bg-success", text: "Finalizado" },
      ASIGNADO: { className: "bg-primary ", text: "Asignado" }
    };
  
    const estadoActual = estados[estado];
  
    return (
      <div className={`badge rounded-pill ${estadoActual.className} font-size-10`} style={{background: estadoActual.background}}>
        <strong>{estadoActual.text}</strong>
      </div>
    );
  }
  console.log(params);

  const handleCloseModal = () => {
    setModalMateriales(false);
    setModalInforme(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Casos </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Casos" />
        {modalMateriales ?(

          <ModalMateriales modalMateriales={modalMateriales} 
          handleCloseModal={handleCloseModal} 
          casosId={casoId}
          getCasosData={getCasosData}
          agent={agent}
          />
        ): null}
        
        {
          modalInforme ? (
            <ModalInforme modalInforme={modalInforme} 
            handleCloseModal={handleCloseModal} 
            casosId={casoId}
            getCasosData={getCasosData}
            />
          ): null
        }
          
     
              {actionAlert ? (
                <SweetAlert
                  title= {`¿${action} caso ${ticket} ?`}
                  warning
                  showConfirm={true}
                  confirmBtnText="Si"
                  showCancel={true}
                  cancelBtnBsStyle="danger"
                  cancelBtnText="No"
                  onCancel={()=> setActionAlert(false)}
                  onConfirm={() => actionSuccess()}                  
                >
                  <Label>Observaciones</Label>
                  <Input
                   type="textarea"
                   id="observaciones"
                   name='observaciones'
                   onChange={(e)=> setObservaciones(e.target.value)}
                   />                  
                </SweetAlert>
              ) : null}
              {showAlertSuccess ? (
                <SweetAlert
                  title= 'Hecho!'
                  success
                  showConfirm={false}
                  timeout={2000}
                  onConfirm={() => {getCasosData(); setShowAlertSuccess(false) }}                  
                >                 
                </SweetAlert>
              ) : null}
              {errorAlert ? (
                <SweetAlert
                  title= 'Error!'
                  danger
                  showConfirm={false}
                  timeout={2000}
                  onConfirm={() => {getCasosData(); setErrorAlert(false) }}                  
                >                 
                </SweetAlert>
              ) : null}
          {loading ? (
            <Loader />
          ) : (
            <div>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Link
                        className="btn btn-primary waves-effect waves-light mb-1"
                        to="/casos/create"
                      >
                        Agregar
                      </Link>
                    </Col>
                    <Col md={5}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Caso, nombre, descripcion..."
                            value={search}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card> 
              {/*BOTONES DE FILTROS*/}
              <Row className="mb-3">
                <Col className="d-flex justify-content-center mb-2" key={botonActivo}>
                <Button 
      key="todas"
      color="pink" 
      style={{ boxShadow: botonActivo === "Todas" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none" }} 
      className="btn-sm bg-pink text-white ms-1" 
      onClick={() => { getCasosData(); setBotonActivo('Todas') }}
    >
      <b>Todas</b> 
    </Button>
    <Button 
      key="asignado"
      color="primary"  
      className="btn-sm ms-1" 
      style={{ boxShadow: botonActivo === "ASIGNADO" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none" }} 
      onClick={() => getCasosPorEstados('ASIGNADO')}
    >
      <b>Asignado</b> 
    </Button>
    <Button 
      key="sin_asignar"
      color="warning"  
      className="btn-sm bg-warning ms-1" 
      style={{ boxShadow: botonActivo === "SIN_ASIGNAR" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none" }} 
      onClick={() => getCasosPorEstados('SIN_ASIGNAR')}
    >
      <b>Sin Asignar</b> 
    </Button>
    <Button 
      key="pendiente"
      className="btn-sm btn-danger ms-1" 
      style={{ boxShadow: botonActivo === "PENDIENTE" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none" }} 
      onClick={() => getCasosPorEstados('PENDIENTE')}
    >
      <b>Pendiente</b> 
    </Button>
    <Button 
      key="en_pausa"
      className="btn-sm btn-info ms-1" 
      style={{ boxShadow: botonActivo === "EN_PAUSA" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none" }} 
      onClick={() => getCasosPorEstados('EN_PAUSA')}
    >
      <b>En Pausa</b> 
    </Button>
    <Button 
      key="en_proceso"
      color="#6f42c1" 
      className="btn-sm text-white ms-1" 
      style={{ background:"#6f42c1", boxShadow: botonActivo === "EN_PROCESO" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none" }} 
      onClick={() => getCasosPorEstados('EN_PROCESO')}
    >
      <b>En Proceso</b> 
    </Button>
    <Button 
      key="finalizado"
      className="btn-sm btn-success ms-1" 
      style={{ boxShadow: botonActivo === "FINALIZADO" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none" }} 
      onClick={() => getCasosPorEstados('FINALIZADO')}
    >
      <b>Finalizado</b> 
    </Button>
                </Col>
              </Row>                  
              <div className={`text-center ${styles['card-container']}`}>                
          {params.results &&
            params.results.map((casos, idx) => (
            <>
              <Card className={styles.ticket} style={{border: '0px'}}> 
               
                <div  >
                  <div  onClick={() => history.push(`/casos/update/${casos.id}`)}   className={styles["ticket-header"]}>  
                    <p className={`mb-0 ${casos.name.startsWith("Desplazamiento") ? "font-size-13" : "font-size-18"}`}>
                      <b>{casos.name}</b>                    </p>
                      <div className="position-absolute top-0 end-0 me-1">{casos.estado ? <>
                        <b><Status estado={casos.estado} /></b> </>: null} 
                      </div>                                
                  </div>   
                
                  <div onClick={() => history.push(`/casos/view/${casos.id}`)} className={styles["ticket-body"]} >
                  {casos.estado === "ASIGNADO" || casos.estado === "SIN_ASIGNAR" ?
                      <Button color="primary" className="btn-sm mt-1 " onClick={() => handleAction('ACEPTAR', casos.name, casos.id)}><IoCheckmarkDoneSharp /> Aceptar</Button> : null
                    }
                    {casos.estado === "PENDIENTE" ?
                      <Button className="btn-sm btn-warning mt-1" onClick={() => handleAction('INICIAR', casos.name, casos.id)}><IoPlaySharp /> Iniciar</Button> : null
                    }
                    {casos.estado === "EN_PROCESO" ?(

                  <div
                style={{ width: "100%", justifyContent: "space-between" }}  
                className="d-flex  mt-1"
                >
                  <Button
                    title="Materiales" 
                       color="link"
                       className={`btn-sm waves-light waves-effect mb-1 ${styles['hover-scale']}`}
                    onClick={(e) => {
                      e.stopPropagation();  
                      handleMateriales(casos.id)
                      }}
                  >
                   <i className="mdi mdi-package-variant" 
                   style={{ color: '#8B4513', fontSize: '24px' }}
                   />
                  </Button>
                  <Button
                   title="Informe" 
                      color="link"
                  //  style={{background: "rgb(232, 62, 140)", color: "white"}}
                  className={`btn-sm waves-light waves-effect mb-1 ${styles['hover-scale']}`}
                    onClick={(e) => { 
                      e.stopPropagation(); 
                      setCasoId(casos.id)
                      setModalInforme(true)}}
                  >
                   <i className="mdi mdi-file-document-outline" 
                     style={{ color: '#808080',  fontSize: '24px' }}
                   />
                  </Button>
                  </div>
                    ): null}


                  {casos.total_duracion ?
                    <p className="font-size-12 text-dark d-flex justify-content-center"><b>Tiempo de Finalizacion: </b> {casos.total_duracion}</p>
                    : null}
                    <p className="font-size-15 mt-3 mb-1"><b>{casos.descripcion}</b></p>
                    {Array.isArray(casos.contacto)
                        ?  casos.contacto.map(item => item[0]).join(', ')
                        : []}
                  </div>
                </div>
                {/* Botones de accion */}
                {casos.estado !== "FINALIZADO" ? <>
                  <div className={styles['ticket-fixed-button']}>
                    {casos.estado === "ASIGNADO" || casos.estado === "SIN_ASIGNAR" ?
                      <Button color="primary" className="btn-sm mt-1 " onClick={() => handleAction('ACEPTAR', casos.name, casos.id)}><IoCheckmarkDoneSharp /> Aceptar</Button> : null
                    }
                    {casos.estado === "PENDIENTE" ?
                      <Button className="btn-sm btn-warning mt-1" onClick={() => handleAction('INICIAR', casos.name, casos.id)}><IoPlaySharp /> Iniciar</Button> : null
                    }
                    {casos.estado === "EN_PROCESO" ?
                      <>

     


<Button className="btn-sm btn-info mt-1" onClick={() => handleAction('PAUSAR', casos.name, casos.id)}><IoPauseSharp /> Pausar</Button>
<Button className="btn-sm btn-success ms-1 mt-1" onClick={() => handleAction('FINALIZAR', casos.name, casos.id)}><IoStopSharp /> Finalizar</Button>

                     
                    
                      
                      </> : null
                    }
                    {casos.estado === "EN_PAUSA" ?
                      <Button 
                      color="pink" 
                      className="btn-sm bg-pink text-white mt-1" 
                      onClick={(e) => {	
                        e.stopPropagation(); 
                         handleAction('REANUDAR', casos.name, casos.id)}}><IoPlaySharp /> Reanudar</Button> : null
                    }
                  </div></>: null }
              </Card >
            </>
            ))}
        </div>
            </div>

          )}
          <Pagination
            count={params.count}
            next={params.next}
            previous={params.previous}
            limit={30}
            filter={botonActivo !== 'Todas'? true : null}
            baseUrl={`${botonActivo === 'Todas' ? '/casos/casos/' : '/casos/casos/casos_por_estado/?estado='+ botonActivo }`}
            filterFunction={botonActivo === 'Todas'? getFilterData : getFilterCasosData }
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = (state) => {
  const { error, loading, casos } = state.Casos;
  return { error, loading, casos };
};

export default withRouter(connect(mapStateToProps)(Casos));
