import React, { useState, useEffect, useRef } from 'react';
import './PhonePanel.css';
import { FaPhone, FaPhoneSlash, FaPhoneAlt, FaArrowRight, FaBackspace } from 'react-icons/fa';
import { getCallEnd, getCallStart, getContactsList } from '../helpers/backend';
import ManagementManual from '../pages/Whatsapp/components/ManagementManual';

const PhonePanel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [callTime, setCallTime] = useState(0);
  const [isCalling, setIsCalling] = useState(false);
  const [dialedNumber, setDialedNumber] = useState('');
  const [isRegistered, setIsRegistered] = useState(null); // Cambiado a null para control
  const [manualOpen, setManualOpen] = useState(false);
  const [channelId, setChannelId] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [contactName, setContactName] = useState(''); // Estado para el nombre del contacto
  const timerRef = useRef(null);

  const getContactsData = async () => {
    const response = await getContactsList();
    setContacts(response.data.results);
    console.log(contacts)
  };

  useEffect(() => {
    getContactsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCalling) {
      timerRef.current = setInterval(() => setCallTime(prevTime => prevTime + 1), 1000);
    } else if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    return () => clearInterval(timerRef.current);
  }, [isCalling]);

  const handleDial = (num) => setDialedNumber(dialedNumber + num);
  const handleInputChange = (e) => setDialedNumber(e.target.value);
  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) e.preventDefault();
  };
  const togglePanel = () => setIsOpen(!isOpen);

  const startCall = async () => {
    const contact = contacts.find(contact =>
      contact.phone.some(phoneEntry => phoneEntry.telefono === `57${dialedNumber}`)
    );
  
    if (contact) {
      setIsRegistered(true);
      setContactName(contact.get_full_name); // Guarda el nombre del contacto
    } else {
      setIsRegistered(false);
      setContactName(''); // Limpia el nombre si no hay coincidencia
    }
  
    setIsCalling(true);
    setCallTime(0);
    setManualOpen(true);
  
    const response = await getCallStart({
      number: `57${dialedNumber}`,
      extension: '1203'
    });
    setChannelId(response.data.channel_id);
  };

  const endCall = async () => {
    await  getCallEnd({
      channel_id: channelId
    })
    setIsCalling(false);
    setDialedNumber('');
    setIsRegistered(null); // Reinicia el estado de registro
    setContactName(''); // Limpia el nombre del contacto
    clearInterval(timerRef.current);
    timerRef.current = null;
  };

  const deleteLastDigit = () => {
    setDialedNumber(prevNumber => prevNumber.slice(0, -1));
  };

  const formatTime = () => {
    const minutes = Math.floor(callTime / 60);
    const seconds = callTime % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div>
      {manualOpen && (
        <ManagementManual 
        
        getContactsData={getContactsData}
        isLead={true} isOpen={manualOpen} onClose={() => setManualOpen(false)} />
      )}
      <button className="floating-button-PhonePanel" onClick={togglePanel}>
        <FaPhoneAlt />
      </button>
      {isOpen && (
        <div className="phone-panel">
          {isRegistered !== null && (
            <div className="registration-status">
              {isRegistered ? (
              <>
                <span className="contact-name">{contactName}</span> <br />
                <span className="registered">Registrado: </span>
              </>
              ) : (
                <span className="not-registered">No Registrado</span>
              )}
            </div>
          )}
          <input
            type="text"
            className="dial-display"
            value={dialedNumber}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Ingrese número"
            disabled={isCalling}
          />
          <div className="dial-pad">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'].map((num) => (
              <button
                key={num}
                className="dial-button"
                onClick={() => handleDial(num)}
                disabled={isCalling}
              >
                {num}
              </button>
            ))}
          </div>
          <div className="call-actions">
            {!isCalling ? (
              <button className="call-button" onClick={startCall} title="Llamar">
                <FaPhone />
              </button>
            ) : (
              <button className="end-call-button" onClick={endCall} title="Colgar">
                <FaPhoneSlash />
              </button>
            )}
            <button className="transfer-button" title="Transferir">
              <FaArrowRight />
            </button>
            <button className="transfer-button font-size-18" onClick={deleteLastDigit} title="Borrar">
              <FaBackspace />
            </button>
          </div>
          {isCalling && <div className="call-timer">Tiempo de llamada: {formatTime()}</div>}
          {isCalling && <div className="call-indicator">En llamada...</div>}
        </div>
      )}
    </div>
  );
};

export default PhonePanel;
