import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  BiSend,
  BiCheck,
  BiCheckDouble,
  BiTransfer,
  BiHistory,
  BiPencil,
  BiPaperclip,
  BiNote,
  BiImage,
  BiMicrophone,
  BiFile,
  BiVideo,
  BiStop,
  BiSmile,
} from "react-icons/bi";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Chat.css";
import {
  Button,
  Row,
  Col,
  Modal,
  Input,
  Alert,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  getAgentOfCampaing,
  getAllChat,
  getCampaignFullList,
  getContactsRead,
  getWhatsappAtajos,
  postAddNotes,
  postTelegramMessage,
  postTransferAgent,
  postTransferCampaign,
  postWhatsappMessage,
} from "../../helpers/backend";
import SweetAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileWord,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { ReactMic } from "react-mic";
import Select from "react-select";

import EmojiPicker from "emoji-picker-react";
import html2canvas from "html2canvas";

function Chat({
  updateManagementOpen,
  updateHistoryOpen,
  updateContactOpen,
  selectedPhoneNumber,
  selectedContact,
  contactsRefresh,
  setContactsRefresh,
  chatType,
  campaignRefresh,
}) {
  const user = JSON.parse(localStorage.getItem("user"));
  const groups = String(user.groups);
  const URL_LARGE = process.env.REACT_APP_API_URL;
  const URL = URL_LARGE.split("/");
  const url = URL_LARGE.split("/api");

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState([]);
  const [newMessageWebsocket, setNewMessageWebsocket] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [notesText, setNotesText] = useState("");
  const [caption, setCaption] = useState("");
  const [imageModal, setImageModal] = useState("");
  const [status, setStatus] = useState(null);
  //const [loading, setLoading] = useState(null)
  const [agent, setAgent] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageOpen, setImageOpen] = useState(null);
  const messageContainerRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [errorSend, SetErrorSend] = useState(false);
  const [alert, setAlert] = useState(false);
  const [modalNotes, setModalNotes] = useState(false);
  const [modaltransfer, setModaltransfer] = useState(false);
  const [showAgentSelect, setShowAgentSelect] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showShortcuts, setShowShortcuts] = useState(false);
  const [filteredShortcuts, setFilteredShortcuts] = useState([]);
  const [allShortcuts, setAllShortcuts] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(0); // Controlar la selección
  const [dragActive, setDragActive] = useState(false); // Para manejar el estado visual de arrastre
  const [copySuccess, setCopySuccess] = useState(false);

  //Funcnion para detectar enlaces
  function formatMessageWithLinks(text = "") {
    if (text === null || text === undefined) {
      return null;
    }

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      ) : (
        part
      )
    );
  }

  const handlePaste = async (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedHtml = clipboardData.getData("text/html");
    const pastedText = clipboardData.getData("text/plain");

    if (pastedHtml) {
      const tableElement = new DOMParser()
        .parseFromString(pastedHtml, "text/html")
        .querySelector("table");
      if (tableElement) {
        const tempDiv = document.createElement("div");
        tempDiv.style.position = "absolute";
        tempDiv.style.left = "-9999px";
        tempDiv.appendChild(tableElement);
        document.body.appendChild(tempDiv);

        const imageUrl = await convertTableToImage(tableElement);
        document.body.removeChild(tempDiv);

        if (imageUrl) {
          sendImageMessage(imageUrl);
        }
      } else {
        // Concatenamos el texto actual con el texto pegado
        setNewMessage((prevMessage) => prevMessage + pastedText);
      }
    } else if (pastedText) {
      // Concatenamos el texto actual con el texto pegado
      setNewMessage((prevMessage) => prevMessage + pastedText);
    }
    const items = event.clipboardData.items;
    for (const item of items) {
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        if (file) {
          setSelectedFile(file);
          setSelectedFileType(getFileType(file.type));
        }
      }
    }
  };
  useEffect(() => {
    const container = messageContainerRef.current;
    if (container) {
      container.addEventListener("paste", handlePaste);
    }
    return () => {
      if (container) {
        container.removeEventListener("paste", handlePaste);
      }
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertTableToImage = (tableElement) => {
    return new Promise((resolve, reject) => {
      html2canvas(tableElement)
        .then((canvas) => {
          resolve(canvas.toDataURL("image/png")); // Retornamos la imagen en formato PNG
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const sendImageMessage = async (imageUrl) => {
    const blob = await fetch(imageUrl).then((res) => res.blob());
    const file = new File([blob], "image_from_table.png", {
      type: "image/png",
    });

    setSelectedFile(file);
    setSelectedFileType("image");
    handleSendFile(); // Enviamos el archivo como imagen
  };
  const handleCopyToClipboard = () => {
    if (contactData.documentNumber) {
      navigator.clipboard.writeText(contactData.documentNumber).then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000); // Reset success message after 2 seconds
      });
    }
  };
  const getFileType = (mimeType) => {
    if (mimeType.startsWith("image/")) {
      return "image";
    } else if (mimeType.startsWith("video/")) {
      return "video";
    } else if (mimeType.startsWith("audio/")) {
      return "audio";
    } else if (
      mimeType === "application/pdf" ||
      mimeType === "application/msword" ||
      mimeType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || // Word DOCX
      mimeType === "application/vnd.ms-excel" || // Excel XLS
      mimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || // Excel XLSX
      mimeType === "application/vnd.ms-powerpoint" || // PowerPoint PPT
      mimeType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" // PowerPoint PPTX
    ) {
      return "document";
    } else if (
      mimeType === "application/zip" ||
      mimeType === "application/x-rar-compressed" ||
      mimeType === "application/x-7z-compressed" ||
      mimeType === "application/x-tar"
    ) {
      return "archive";
    } else {
      return "unknown";
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!dragActive) setDragActive(true); // Mantener la dropzone activa
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Verificar si el arrastre salió completamente de la dropzone
    if (e.target === e.currentTarget) {
      setDragActive(false);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      console.log("Archivo arrastrado:", file);

      // Obtener el tipo de archivo usando el tipo MIME
      const fileType = getFileType(file.type);
      console.log("Tipo de archivo:", fileType);

      // Actualizar el estado con el archivo y su tipo
      setSelectedFile(file);
      setSelectedFileType(fileType); // Actualiza el estado con el tipo de archivo
    }
    setDragActive(false);
  };

  const fetchShortcuts = async () => {
    try {
      const response = await getWhatsappAtajos();
      if (Array.isArray(response.data)) {
        setAllShortcuts(response.data);
      } else {
        setAllShortcuts(response.data.shortcuts || []);
      }
    } catch (error) {
      console.error("Error al obtener los atajos:", error);
      setAllShortcuts([]);
    }
  };
  const startRecording = () => {
    setIsRecording(true);
    setSeconds(0);
  };
  const stopRecording = () => {
    setIsRecording(false);
  };
  const onData = (recordedBlob) => {
    console.log("Chunk of real time data is: ", recordedBlob);
  };
  const onStop = (recordedBlob) => {
    const audioFile = new File(
      [recordedBlob.blob],
      `recording-${Date.now()}.ogg`,
      { type: "audio/ogg" }
    );
    setSelectedFileType("audio");
    setSelectedFile(audioFile);
  };
  useEffect(() => {
    if (selectedFileType === "audio" && selectedFile) {
      handleSendFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const handleSendFile = async () => {
    if (isSending || !selectedFile) return;
    setIsSending(true);
    const formData = new FormData();
    formData.append("phone", selectedPhoneNumber);
    formData.append("type", selectedFileType);
    formData.append("file", selectedFile);
    formData.append("caption", caption); // El subtítulo para la imagen
    try {
      let response;

      if (chatType === "WhatsApp") {
        response = await postWhatsappMessage(formData);
        console.log(response);
      } else if (chatType === "Telegram") {
        response = await postTelegramMessage(formData);
      } else {
        console.error("Tipo de chat no válido");
        return;
      }
      if (response.status !== 200) {
        // Manejo de error
      }
      resetFileSelection(); // Reseteamos la selección de archivo
    } catch (error) {
      console.error("Error al enviar el archivo:", error);
    } finally {
      setIsSending(false);
    }
  };
  const resetFileSelection = () => {
    setSelectedFile(null);
    setSelectedFileType("");
    setCaption("");
  };
  const openFileSelector = (type, inputId) => {
    setSelectedFileType(type);
    console.log(type);
    document.getElementById(inputId).click();
  };
  const toggleDropdown = (idx) => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleEmojiClick = (emojiObject) => {
    console.log("Emoji Object:", emojiObject); // Depuración
    setNewMessage((prevMessage) => prevMessage + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const addNotes = async () => {
    const response = await postAddNotes(selectedPhoneNumber, notesText);
    setStatus(response.status);
    setAlert(true);
    setNotesText("");
  };
  const transferAgent = async () => {
    if (showAgentSelect) {
      const response = await postTransferAgent(selectedPhoneNumber, {
        new_agent: agent,
      });
      setStatus(response.status);
      setAlert(true);
      setAgent("");
    } else {
      const response = await postTransferCampaign(
        selectedPhoneNumber,
        campaign
      );
      setStatus(response.status);
      setAlert(true);
      // getChatData(phoneNumber ? phoneNumber : selectedPhoneNumber);
      setAgent("");
    }
    window.location.reload();
  };
  const handleSendFileKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendFile();
    }
  };
  const handleSendMessage = async () => {
    if (isSending) return;

    setIsSending(true);

    try {
      // Enviar mensaje de texto
      const response = await postWhatsappMessage({
        phone: selectedPhoneNumber,
        type: "text",
        body: newMessage,
      });

      if (response.status === 500) {
        // Manejo de error
      }

      setNewMessage("");
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
    } finally {
      setIsSending(false);
    }

    campaignRefresh(true);
  };
  const handleSendTelegramMessage = async () => {
    const response = await postTelegramMessage({
      phone: selectedPhoneNumber,
      type: "text",
      body: newMessage,
    });
    if (response.status === 500) {
      SetErrorSend(true);
    }
    setNewMessage("");
  };
  const handleInputKeyDown = (event) => {
    // Detecta si la tecla Enter fue presionada
    if (event.key === "Enter") {
      // Si se presiona Shift + Enter, no envíes el mensaje, solo agrega una nueva línea
      if (event.shiftKey) {
        return; // No hacemos nada, permitimos que el textarea agregue una nueva línea
      } else {
        // Si no se presiona Shift, manejamos el envío del mensaje
        event.preventDefault(); // Evita que el enter agregue una nueva línea automáticamente
        if (showShortcuts && filteredShortcuts.length > 0) {
          // Si el menú de atajos está visible y hay opciones filtradas
          const selectedShortcut = filteredShortcuts[highlightedIndex];
          if (selectedShortcut) {
            // Asigna el texto del atajo al mensaje
            setNewMessage(selectedShortcut.text);
            setShowShortcuts(false); // Cierra el menú de atajos
            setHighlightedIndex(0); // Reinicia el índice
          }
        } else {
          // Lógica normal de envío de mensajes
          if (chatType === "WhatsApp") {
            handleSendMessage();
          } else {
            handleSendTelegramMessage();
          }
        }
      }
    }

    // Aquí puedes manejar otras teclas si es necesario, como las flechas
    if (event.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        Math.min(prevIndex + 1, filteredShortcuts.length - 1)
      );
    } else if (event.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setNewMessage(value);

    if (value.startsWith("/")) {
      const query = value.slice(1).toLowerCase();
      const filtered = allShortcuts.filter((shortcut) => {
        return (
          typeof shortcut.atajo === "string" &&
          shortcut.atajo.toLowerCase().includes(query)
        );
      });
      setFilteredShortcuts(filtered);
      setShowShortcuts(filtered.length > 0); // Mostrar el menú si hay coincidencias
    } else {
      setShowShortcuts(false); // Ocultar el menú si no hay coincidencias
    }
  };
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-").map(Number);
    // Restamos 1 al mes porque en JavaScript los meses van de 0 a 1  1
    const date = new Date(Date.UTC(year, month - 1, day));
    const today = new Date();

    // Comparamos solo año, mes y día
    if (
      date.getUTCFullYear() === today.getUTCFullYear() &&
      date.getUTCMonth() === today.getUTCMonth() &&
      date.getUTCDate() === today.getUTCDate()
    ) {
      return "HOY";
    } else {
      return date.toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
        year: "numeric",
        timeZone: "UTC",
      });
    }
  };
  const toggleDarkMode = () => {
    setDarkMode(!darkMode); // Cambia el estado del modo oscuro
  };
  console.log(toggleDarkMode);
  const handleManagementButtonClick = () => {
    updateManagementOpen(true);
  };
  const handleHistoryButtonClick = () => {
    updateHistoryOpen(true);
  };
  const handleContactButtonClick = () => {
    updateContactOpen(true);
  };
  function getDocumentIcon(documentPath) {
    const extension = documentPath.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return (
          <FontAwesomeIcon
            icon={faFilePdf}
            className="document-icon mt-1"
            style={{ fontSize: "35px" }}
          />
        );
      case "doc":
      case "docx":
        return (
          <FontAwesomeIcon
            icon={faFileWord}
            className="document-icon mt-1"
            style={{ size: "15px" }}
          />
        );
      default:
        return (
          <FontAwesomeIcon
            icon={faFile}
            className="document-icon mt-1"
            style={{ size: "15px" }}
          />
        );
    }
  }
  const [contactData, setContactData] = useState({});
  const getContactsData = async (id) => {
    const response = await getContactsRead(id);
    const data = response.data;
    setContactData(data);
  };
  const [agentData, setAgentData] = useState({});
  const getAgentData = async () => {
    const response = await getAgentOfCampaing(campaign);
    const data = response.data;
    setAgentData(data);
  };
  const [campaignData, setCampaignData] = useState({});
  const getCampaignData = async () => {
    const response = await getCampaignFullList();
    const data = response.data;
    setCampaignData(data);
  };
  const getChatData = useCallback(async (id) => {
    setNewMessageWebsocket([]);
    try {
      const response = await getAllChat(id);
      if (response.data) {
        const data = response.data;
        setMessages(data);
      } else {
        console.log("No se encontraron datos válidos en la respuesta.");
      }
    } catch (error) {
      console.error("Ocurrió un error al obtener los datos del chat:", error);
    }
    setSelectedFile("");
  }, []);

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRecording]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages, newMessageWebsocket]);

  useEffect(() => {
    if (selectedContact) {
      getContactsData(selectedContact);
    }

    getChatData(selectedPhoneNumber ? selectedPhoneNumber : null);
    getCampaignData();
    fetchShortcuts();
    setContactsRefresh();
    //    setChangeTemplate(template)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact, selectedPhoneNumber, contactsRefresh]);

  useEffect(() => {
    if (campaign) {
      getAgentData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  const chatSocket = useRef(null);
  useEffect(() => {
    const socketUrl = `wss://${URL[2]}/ws/whatsapp/${
      selectedPhoneNumber ? selectedPhoneNumber : null
    }/`;
    chatSocket.current = new WebSocket(socketUrl);

    chatSocket.current.onopen = () => {
      console.log("websocket conectado");
    };
    chatSocket.current.onclose = () => {
      console.log("websocket desconectado");
    };
    chatSocket.current.onmessage = (event) => {
      console.log("Mensaje recibido:", event.data);
      const newMessage = JSON.parse(event.data);
      setNewMessageWebsocket((prevMessages) => {
        const existingMessage = prevMessages.find(
          (message) => message.message.number === newMessage.message.number
        );
        if (existingMessage) {
          return prevMessages.map((message) => {
            if (message.message.number === newMessage.message.number) {
              return {
                ...message,
                message: {
                  ...message.message,
                  status: newMessage.message.status, // Actualiza solo el estado
                },
              };
            } else {
              return message; // Mantén los mensajes que no se actualizan
            }
          });
        } else {
          return [...prevMessages, newMessage];
        }
      });
    };
    return () => {
      // Cerrar el websocket al desmontar el componente
      chatSocket.current.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPhoneNumber]);
  return (
    <>
      <Modal
        isOpen={imageOpen}
        toggle={() => {
          setImageOpen(false);
          setImageModal("");
        }}
        size="xl"
      >
        <div className="d-flex justify-content-center p-2">
          <div>
            <img className="max-size-img-modal" alt="Imagen" src={imageModal} />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modaltransfer}
        toggle={() => {
          setModaltransfer(false);
          setCampaign([]);
          setShowAgentSelect(false);
        }}
        centered={true}
      >
        <div className="modal-header">
          <h3 className="mt-2">Transferir Conversación </h3>
          <button
            type="button"
            onClick={() => {
              setModaltransfer(false);
              setCampaign([]);
              setShowAgentSelect(false);
            }}
            className="btn-close btn-sm"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="p-3 mt-2">
          <Select
            id="campaign"
            name="campaign"
            options={
              Array.isArray(campaignData)
                ? campaignData.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))
                : []
            }
            onChange={(e) => setCampaign(e.value)}
            placeholder={"Seleccione"}
            isMulti={false}
            menuPosition="fixed"
          />
          <div className="form-check mt-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="showAgentSelectCheckbox"
              onChange={(e) => setShowAgentSelect(e.target.checked)}
            />
            <label
              className="form-check-label"
              htmlFor="showAgentSelectCheckbox"
            >
              Desea asignar al Agente?
            </label>
          </div>
          {showAgentSelect && (
            <Select
              id="agent"
              name="agent"
              options={
                Array.isArray(agentData)
                  ? agentData.map((item) => ({
                      label: (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {item.status === "DESCONECTADO" ? (
                            <div
                              style={{
                                backgroundColor: "red",
                                width: "10px",
                                height: "10px",
                                borderRadius: "20px",
                                marginRight: "10px",
                                marginTop: "5px",
                              }}
                            ></div>
                          ) : (
                            <div
                              style={{
                                backgroundColor: "green",
                                width: "10px",
                                height: "10px",
                                borderRadius: "20px",
                                marginRight: "10px",
                                marginTop: "5px",
                              }}
                            ></div>
                          )}
                          <span>{item.get_full_name}</span>
                        </div>
                      ),
                      value: item.id,
                    }))
                  : []
              }
              onChange={(e) => setAgent(e.value)}
              placeholder={"Seleccione"}
              isMulti={false}
              menuPosition="fixed"
            />
          )}
        </div>

        <Row className="mt-3 p-2">
          <Col className="d-flex justify-content-end">
            <Button
              className="text-dark btn btn-secondary"
              onClick={() => {
                setModaltransfer(false);
                setCampaign([]);
                setShowAgentSelect(false);
              }}
            >
              Cerrar
            </Button>
            <Button
              color="primary"
              className="btn  ms-1"
              onClick={transferAgent}
            >
              Transferir{" "}
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        isOpen={modalNotes}
        toggle={() => setModalNotes(false)}
        centered={true}
      >
        <div className="modal-header">
          <h3 className="mt-2">Agregar Nota</h3>
          <button
            type="button"
            onClick={() => setModalNotes(false)}
            className="btn-close btn-sm"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="p-3 mt-2">
          <Input
            type="textarea"
            placeholder="Escribe un comentario..."
            value={notesText}
            onChange={(e) => setNotesText(e.target.value)}
          />
        </div>

        <Row className="mt-3 p-2">
          <Col className="d-flex justify-content-end">
            <Button
              className="text-dark btn btn-secondary"
              onClick={() => setModalNotes(false)}
            >
              Cerrar
            </Button>
            <Button color="primary" className="btn  ms-1" onClick={addNotes}>
              Guardar{" "}
            </Button>
          </Col>
        </Row>
      </Modal>
      {status === 200 && alert ? (
        <SweetAlert
          title="Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setAlert(false);
            setModalNotes(false);
            setModaltransfer(false);
          }}
        >
          {modalNotes
            ? "Se ha guardado la gestión con éxito"
            : "Se ha transferido la conversación correctamente"}
        </SweetAlert>
      ) : null}
      {status !== 200 && alert ? (
        <SweetAlert
          title="Error"
          error
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setAlert(false);
            setModalNotes(false);
            setModaltransfer(false);
          }}
        >
          {" "}
          {modalNotes ? "No se ha creado la Gestión" : "Error al transferir"}
        </SweetAlert>
      ) : null}

      <Row>
        {selectedPhoneNumber ? (
          <div className="chat-container">
            <div
              className={
                chatType === "Telegram"
                  ? "chat-header-telegram"
                  : darkMode
                  ? "chat-header"
                  : "chat-header-light"
              }
            >
              <div>
                <h3
                  className={
                    chatType === "Telegram"
                      ? "contact-name text-white"
                      : "contact-name"
                  }
                  onClick={handleContactButtonClick}
                  style={{ display: "inline-flex", alignItems: "center" }} // Alinea el contenido
                >
                  {contactData.get_full_name ? contactData.get_full_name : "Contacto Desconocido" }
                </h3>
                {contactData.documentNumber && (
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                    onClick={handleCopyToClipboard}
                    title="Copy"
                  >
                    <b className="text-dark">{contactData.documentNumber} </b>{" "}
                    <i className="fas fa-copy mt-1 ms-2" />
                  </span>
                )}
                {copySuccess && (
                  <p style={{ color: "green", fontSize: "12px" }}>
                    ¡Número copiado!
                  </p>
                )}

                <p className="contact-status">
                  {selectedPhoneNumber ? selectedPhoneNumber : null}
                </p>
              </div>
            </div>
            <div
              className={
                chatType === "Telegram"
                  ? "message-container-telegram"
                  : "message-container-light"
              }
              ref={messageContainerRef}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <div className="floating-buttons">
                <div
                  className="tooltip-container me-1"
                  onClick={handleManagementButtonClick}
                >
                  <span className="tooltip">Crear</span>
                  <BiPencil className="text floating-button" />
                </div>
                {groups.includes("SuperAdmin") ||  groups.includes("Administrador") ||
                groups.includes("Supervisor") ? (
                  <div
                    className="tooltip-container me-1"
                    onClick={handleHistoryButtonClick}
                  >
                    <span className="tooltip">Atajos</span>
                    <BiHistory className="floating-button" />
                  </div>
                ) : null}
                <div
                  className="tooltip-container me-1"
                  onClick={() => setModaltransfer(true)}
                >
                  <span className="tooltip">Transferir</span>
                  <BiTransfer className={"floating-button"} />
                </div>
              </div>
              {Array.isArray(messages)
                ? messages.map((messageObj, index) => (
                    <div key={index}>
                      <div className="parent-element">
                        <div
                          className={
                            darkMode
                              ? "date-message mt-1"
                              : "date-message-light mt-1"
                          }
                        >
                          {formatDate(messageObj.date)}
                        </div>
                      </div>
                      <br />
                      {messageObj.messages.map((message, messageIndex) => (
                        <div key={messageIndex}>
                          {message.msg_type === "nota" ? (
                            <Col
                              lg={12}
                              className="d-flex justify-content-end mt-2"
                            >
                              <Alert
                                color="info"
                                role="alert"
                                className="p-1 mb-0"
                                style={{ fontSize: "smaller" }}
                              >
                                <div>
                                  <b>{message.contact}</b>
                                </div>
                                <div>{message.body}</div>
                              </Alert>
                            </Col>
                          ) : message.msg_type === "image" ? (
                            <div
                              className={
                                message.role === "From_me"
                                  ? !darkMode
                                    ? "sent-message-light"
                                    : "sent-message"
                                  : !darkMode
                                  ? "received-message-light"
                                  : "received-message"
                              }
                            >
                              {message.role === "From_me" ? (
                                <div
                                  className={
                                    !darkMode
                                      ? "agent-name-light"
                                      : "agent-name"
                                  }
                                >
                                  <b>{message.contact}</b>
                                </div>
                              ) : null}
                              <div className="message-content mt-2">
                                <img
                                  className="max-size-img"
                                  src={url[0] + message.file}
                                  alt="Imagen"
                                  onClick={() => {
                                    setImageOpen(true);
                                    setImageModal(url[0] + message.file);
                                  }}
                                />
                              </div>
                              <div className="message-text mt-2">
                                {message.caption}
                              </div>
                              <div className="mt-2">
                                <div
                                  className={
                                    darkMode
                                      ? "message-time"
                                      : "message-time-light"
                                  }
                                >
                                  {message.time}
                                </div>
                                {message.role === "From_me" &&
                                  message.status === "read" && (
                                    <BiCheckDouble className="checkIcon-message read-icon" />
                                  )}
                                {message.role === "From_me" &&
                                  message.status === "sent" && (
                                    <BiCheck className="checkIcon-message delivered-icon" />
                                  )}
                                {message.role === "From_me" &&
                                  message.status === "delivered" && (
                                    <BiCheckDouble className="checkIcon-message" />
                                  )}
                              </div>
                            </div>
                          ) : message.msg_type === "audio" ? (
                            <div
                              className={
                                message.role === "From_me"
                                  ? !darkMode
                                    ? "sent-message-light"
                                    : "sent-message"
                                  : !darkMode
                                  ? "received-message-light"
                                  : "received-message"
                              }
                            >
                              {message.role === "From_me" ? (
                                <div
                                  className={
                                    !darkMode
                                      ? "agent-name-light"
                                      : "agent-name"
                                  }
                                >
                                  <b>{message.contact}</b>
                                </div>
                              ) : null}
                              <div className="message-content mt-2">
                                <audio className="audio-player" controls>
                                  <source
                                    src={url[0] + message.file}
                                    type="audio/mpeg"
                                  />
                                  Your browser does not support the audio
                                  element.
                                </audio>
                              </div>
                              <div className="message-text mt-1">
                                {message.caption}
                              </div>
                              <div className="mt-1">
                                <div
                                  className={
                                    darkMode
                                      ? "message-time"
                                      : "message-time-light"
                                  }
                                >
                                  {message.time}
                                </div>
                                {message.role === "From_me" &&
                                  message.status === "read" && (
                                    <BiCheckDouble className="checkIcon-message read-icon" />
                                  )}
                                {message.role === "From_me" &&
                                  message.status === "sent" && (
                                    <BiCheck className="checkIcon-message delivered-icon" />
                                  )}
                                {message.role === "From_me" &&
                                  message.status === "delivered" && (
                                    <BiCheckDouble className="checkIcon-message" />
                                  )}
                              </div>
                            </div>
                          ) : message.msg_type === "video" ? (
                            <div
                              className={
                                message.role === "From_me"
                                  ? !darkMode
                                    ? "sent-message-light"
                                    : "sent-message"
                                  : !darkMode
                                  ? "received-message-light"
                                  : "received-message"
                              }
                            >
                              {message.role === "From_me" ? (
                                <div
                                  className={
                                    !darkMode
                                      ? "agent-name-light"
                                      : "agent-name"
                                  }
                                >
                                  <b>{message.contact}</b>
                                </div>
                              ) : null}
                              <div className="message-content mt-2">
                                <video controls className="max-size-img">
                                  <source
                                    src={url[0] + message.file}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video
                                  element.
                                </video>
                              </div>
                              <div className="message-text mt'1">
                                {message.caption}
                              </div>
                              <div className="mt-2">
                                <div
                                  className={
                                    darkMode
                                      ? "message-time"
                                      : "message-time-light"
                                  }
                                >
                                  {message.time}
                                </div>
                                {message.role === "From_me" &&
                                  message.status === "read" && (
                                    <BiCheckDouble className="checkIcon-message read-icon" />
                                  )}
                                {message.role === "From_me" &&
                                  message.status === "sent" && (
                                    <BiCheck className="checkIcon-message delivered-icon" />
                                  )}
                                {message.role === "From_me" &&
                                  message.status === "delivered" && (
                                    <BiCheckDouble className="checkIcon-message" />
                                  )}
                              </div>
                            </div>
                          ) : message.msg_type === "document" ? (
                            <div
                              className={
                                message.role === "From_me"
                                  ? !darkMode
                                    ? "sent-message-light"
                                    : "sent-message"
                                  : !darkMode
                                  ? "received-message-light"
                                  : "received-message"
                              }
                            >
                              {message.role === "From_me" ? (
                                <div
                                  className={
                                    !darkMode
                                      ? "agent-name-light"
                                      : "agent-name"
                                  }
                                >
                                  <b>{message.contact}</b>
                                </div>
                              ) : null}
                              <div className="message-content mt-2">
                                <a
                                  href={url[0] + message.file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="me-1 text-black"
                                >
                                  {getDocumentIcon(message.file)}
                                  {message.filename}
                                </a>
                              </div>
                              <div className="message-text mt-1">
                                {message.caption}
                              </div>
                              <div className="mt-2">
                                <div
                                  className={
                                    darkMode
                                      ? "message-time"
                                      : "message-time-light"
                                  }
                                >
                                  {message.time}
                                </div>
                                {message.role === "From_me" &&
                                  message.status === "read" && (
                                    <BiCheckDouble className="checkIcon-message read-icon" />
                                  )}
                                {message.role === "From_me" &&
                                  message.status === "sent" && (
                                    <BiCheck className="checkIcon-message delivered-icon" />
                                  )}
                                {message.role === "From_me" &&
                                  message.status === "delivered" && (
                                    <BiCheckDouble className="checkIcon-message" />
                                  )}
                              </div>
                            </div>
                          ) : (
                            <div
                              className={
                                message.role === "From_me"
                                  ? !darkMode
                                    ? "sent-message-light"
                                    : "sent-message"
                                  : !darkMode
                                  ? "received-message-light"
                                  : "received-message"
                              }
                            >
                              {message.role === "From_me" ? (
                                <div
                                  className={
                                    !darkMode
                                      ? "agent-name-light"
                                      : "agent-name"
                                  }
                                >
                                  <b>{message.contact}</b>
                                </div>
                              ) : null}
                              <div className="message-content">
                                <div className="message-text">
                                  {" "}
                                  {formatMessageWithLinks(message.body)}
                                </div>
                              </div>
                              <div className="mt-2">
                                <div
                                  className={
                                    darkMode
                                      ? "message-time"
                                      : "message-time-light"
                                  }
                                >
                                  {message.time}
                                </div>
                                {message.role === "From_me" &&
                                message.status === "read" ? (
                                  <BiCheckDouble className="checkIcon-message read-icon" />
                                ) : message.role === "From_me" &&
                                  message.status === "sent" ? (
                                  <BiCheck className="checkIcon-message delivered-icon" />
                                ) : message.role === "From_me" &&
                                  message.status === "delivered" ? (
                                  <BiCheckDouble className="checkIcon-message" />
                                ) : null}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))
                : []}
              {Array.isArray(newMessageWebsocket)
                ? newMessageWebsocket.map((messageObj, index) => {
                    return (
                      <div key={index}>
                        {messageObj.message.msg_type === "nota" ? (
                          <Col
                            lg={12}
                            className="d-flex justify-content-end mt-2"
                          >
                            <Alert
                              color="info"
                              role="alert"
                              className="p-1 mb-0"
                              style={{ fontSize: "smaller" }}
                            >
                              <div>
                                <b>{messageObj.message.contact}</b>
                              </div>
                              <div>{messageObj.message.body}</div>
                            </Alert>
                          </Col>
                        ) : messageObj.message.msg_type === "image" ? (
                          <div
                            className={
                              messageObj.message.role === "From_me"
                                ? !darkMode
                                  ? "sent-message-light"
                                  : "sent-message"
                                : !darkMode
                                ? "received-message-light"
                                : "received-message"
                            }
                          >
                            {messageObj.message.role === "From_me" ? (
                              <div
                                className={
                                  !darkMode ? "agent-name-light" : "agent-name"
                                }
                              >
                                <b>{messageObj.message.contact}</b>
                              </div>
                            ) : null}
                            <div className="message-content mt-2">
                              <img
                                className="max-size-img"
                                src={url[0] + messageObj.message.file}
                                alt="Imagen"
                                onClick={() => {
                                  setImageOpen(true);
                                  setImageModal(
                                    url[0] + messageObj.message.file
                                  );
                                }}
                              />
                            </div>
                            <div className="message-text mt-2">
                              {messageObj.message.caption}
                            </div>
                            <div className="mt-2">
                              <div
                                className={
                                  darkMode
                                    ? "message-time"
                                    : "message-time-light"
                                }
                              >
                                {messageObj.message.time}
                              </div>
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "read" && (
                                  <BiCheckDouble className="checkIcon-message read-icon" />
                                )}
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "sent" && (
                                  <BiCheck className="checkIcon-message delivered-icon" />
                                )}
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "delivered" && (
                                  <BiCheckDouble className="checkIcon-message" />
                                )}
                            </div>
                          </div>
                        ) : messageObj.message.msg_type === "audio" ? (
                          <div
                            className={
                              messageObj.message.role === "From_me"
                                ? !darkMode
                                  ? "sent-message-light"
                                  : "sent-message"
                                : !darkMode
                                ? "received-message-light"
                                : "received-message"
                            }
                          >
                            {messageObj.message.role === "From_me" ? (
                              <div
                                className={
                                  !darkMode ? "agent-name-light" : "agent-name"
                                }
                              >
                                <b>{messageObj.message.contact}</b>
                              </div>
                            ) : null}
                            <div className="message-content mt-2">
                              <audio className="audio-player" controls>
                                <source
                                  src={url[0] + messageObj.message.file}
                                  type="audio/mpeg"
                                />
                                Your browser does not support the audio element.
                              </audio>
                            </div>
                            <div className="message-text mt-1">
                              {messageObj.message.caption}
                            </div>
                            <div className="mt-1">
                              <div
                                className={
                                  darkMode
                                    ? "message-time"
                                    : "message-time-light"
                                }
                              >
                                {messageObj.message.time}
                              </div>
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "read" && (
                                  <BiCheckDouble className="checkIcon-message read-icon" />
                                )}
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "sent" && (
                                  <BiCheck className="checkIcon-message delivered-icon" />
                                )}
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "delivered" && (
                                  <BiCheckDouble className="checkIcon-message" />
                                )}
                            </div>
                          </div>
                        ) : messageObj.message.msg_type === "video" ? (
                          <div
                            className={
                              messageObj.message.role === "From_me"
                                ? !darkMode
                                  ? "sent-message-light"
                                  : "sent-message"
                                : !darkMode
                                ? "received-message-light"
                                : "received-message"
                            }
                          >
                            {messageObj.message.role === "From_me" ? (
                              <div
                                className={
                                  !darkMode ? "agent-name-light" : "agent-name"
                                }
                              >
                                <b>{messageObj.message.contact}</b>
                              </div>
                            ) : null}
                            <div className="message-content mt-2">
                              <video controls className="max-size-img">
                                <source
                                  src={url[0] + messageObj.message.file}
                                  type="video/mp4"
                                />
                                Your browser does not support the video element.
                              </video>
                            </div>
                            <div className="message-text mt'1">
                              {messageObj.message.caption}
                            </div>
                            <div className="mt-2">
                              <div
                                className={
                                  darkMode
                                    ? "message-time"
                                    : "message-time-light"
                                }
                              >
                                {messageObj.message.time}
                              </div>
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "read" && (
                                  <BiCheckDouble className="checkIcon-message read-icon" />
                                )}
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "sent" && (
                                  <BiCheck className="checkIcon-message delivered-icon" />
                                )}
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "delivered" && (
                                  <BiCheckDouble className="checkIcon-message" />
                                )}
                            </div>
                          </div>
                        ) : messageObj.message.msg_type === "document" ? (
                          <div
                            className={
                              messageObj.message.role === "From_me"
                                ? !darkMode
                                  ? "sent-message-light"
                                  : "sent-message"
                                : !darkMode
                                ? "received-message-light"
                                : "received-message"
                            }
                          >
                            {messageObj.message.role === "From_me" ? (
                              <div
                                className={
                                  !darkMode ? "agent-name-light" : "agent-name"
                                }
                              >
                                <b>{messageObj.message.contact}</b>
                              </div>
                            ) : null}
                            <div className="message-content mt-2">
                              <a
                                href={url[0] + messageObj.message.file}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {getDocumentIcon(messageObj.message.file)}
                                {messageObj.message.filename}
                              </a>
                            </div>
                            <div className="message-text mt-1">
                              {messageObj.message.caption}
                            </div>
                            <div className="mt-2">
                              <div
                                className={
                                  darkMode
                                    ? "message-time"
                                    : "message-time-light"
                                }
                              >
                                {messageObj.message.time}
                              </div>
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "read" && (
                                  <BiCheckDouble className="checkIcon-message read-icon" />
                                )}
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "sent" && (
                                  <BiCheck className="checkIcon-message delivered-icon" />
                                )}
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "delivered" && (
                                  <BiCheckDouble className="checkIcon-message" />
                                )}
                            </div>
                          </div>
                        ) : (
                          <div
                            className={
                              messageObj.message.role === "From_me"
                                ? !darkMode
                                  ? "sent-message-light"
                                  : "sent-message"
                                : !darkMode
                                ? "received-message-light"
                                : "received-message"
                            }
                          >
                            {messageObj.message.role === "From_me" ? (
                              <div
                                className={
                                  !darkMode ? "agent-name-light" : "agent-name"
                                }
                              >
                                <b>{messageObj.message.contact}</b>
                              </div>
                            ) : null}
                            <div className="message-content">
                              <div className="message-text">
                                {" "}
                                {formatMessageWithLinks(
                                  messageObj.message.body
                                )}
                              </div>
                            </div>
                            <div className="mt-2">
                              <div
                                className={
                                  darkMode
                                    ? "message-time"
                                    : "message-time-light"
                                }
                              >
                                {messageObj.message.time}
                              </div>
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "read" && (
                                  <BiCheckDouble className="checkIcon-message read-icon" />
                                )}
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "sent" && (
                                  <BiCheck className="checkIcon-message delivered-icon" />
                                )}
                              {messageObj.message.role === "From_me" &&
                                messageObj.message.status === "delivered" && (
                                  <BiCheckDouble className="checkIcon-message" />
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })
                : []}
              {errorSend ? (
                <p className="text-danger d-flex justify-content-end mt-2">
                  ERROR!!! No se puede enviar el mensaje
                </p>
              ) : null}
            </div>
            {selectedFile && selectedFileType !== "audio" ? (
              <div className="selected-file-container">
                <div className="selected-file-card">
                  <p>
                    <b>{selectedFile.type}:</b> {selectedFile.name}
                  </p>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => resetFileSelection()}
                  >
                    <i className="fas fa-times"></i>
                  </span>
                </div>
                <div className="selected-file-card">
                  <Input
                    type="textarea"
                    value={caption}
                    onChange={(e) => setCaption(e.target.value)}
                    onKeyDown={handleSendFileKeyDown}
                    placeholder="Añade un comentario"
                  />
                  <button className="send-button ms-4" onClick={handleSendFile}>
                    <BiSend />
                  </button>
                </div>
              </div>
            ) : (
              <div
                className={` flex-container  ${
                  darkMode ? "input-container" : "input-container-light"
                }`}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {!isRecording ? (
                  <>
                    {showShortcuts && (
                      <ul className="shortcut-menu">
                        {filteredShortcuts.map((shortcut, index) => (
                          <li
                            key={shortcut.id}
                            className={
                              highlightedIndex === index ? "highlighted" : ""
                            }
                            onClick={() => {
                              setNewMessage(shortcut.text);
                              setShowShortcuts(false);
                              setHighlightedIndex(0);
                            }}
                          >
                            {shortcut.atajo}{" "}
                            {/* Muestra el atajo correctamente */}
                          </li>
                        ))}
                      </ul>
                    )}
                    <Input
                      type="textarea"
                      className="input-field"
                      placeholder="Escribe un mensaje..."
                      value={newMessage}
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyDown}
                      onPaste={handlePaste}
                    />
                  </>
                ) : null}
                <div>
                  {!isRecording ? (
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={toggleDropdown}
                      className="custom-dropdown"
                    >
                      <DropdownToggle tag="span" onClick={toggleDropdown}>
                        <BiPaperclip className="add-note-button" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => setModalNotes(true)}>
                          <BiNote color="#d800cdc4" size="25px" /> Notas
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            openFileSelector("image", "imageInput")
                          }
                        >
                          <BiImage color="orange" size="25px" /> Imágenes
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            openFileSelector("document", "documentInput")
                          }
                        >
                          <BiFile color="#ff8383c4" size="25px" /> Documento
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            openFileSelector("audio", "audioInput")
                          }
                        >
                          <BiMicrophone color="#33a4d8" size="25px" /> Audio
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            openFileSelector("video", "videoInput")
                          }
                        >
                          <BiVideo color="#073a63" size="25px" /> Video
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  ) : null}
                  <input
                    id="imageInput"
                    type="file"
                    accept="image/*"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    style={{ display: "none" }}
                  />
                  <input
                    id="audioInput"
                    type="file"
                    accept="audio/*"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    style={{ display: "none" }}
                  />
                  <input
                    id="documentInput"
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    style={{ display: "none" }}
                  />
                  <input
                    id="videoInput"
                    type="file"
                    accept="video/*"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    style={{ display: "none" }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: "none" }}>
                    <ReactMic
                      record={isRecording}
                      className="sound-wave"
                      onStop={onStop}
                      onData={onData}
                      height="40"
                    />
                  </div>
                  {isRecording && (
                    <>
                      <BiMicrophone
                        className="blink text-danger"
                        style={{ marginRight: "10px", fontSize: "20px" }}
                      />
                      <span style={{ marginRight: "10px", fontSize: "20px" }}>
                        {" "}
                        <b>{seconds} seg.</b>
                      </span>
                    </>
                  )}
                  {isRecording ? (
                    <BiStop
                      onClick={stopRecording}
                      style={{
                        fontSize: "40px",
                        cursor: "pointer",
                        marginRight: "5px",
                      }}
                    />
                  ) : (
                    <>
                      <BiSmile
                        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                        className="control-button"
                      />
                      {showEmojiPicker && (
                        <div
                          className="position-absolute"
                          style={{ bottom: 50, right: 10 }}
                        >
                          <EmojiPicker onEmojiClick={handleEmojiClick} />
                        </div>
                      )}
                      <BiMicrophone
                        onClick={startRecording}
                        className="control-button"
                      />
                      <button
                        className="send-button"
                        onClick={
                          chatType === "WhatsApp"
                            ? handleSendMessage
                            : handleSendTelegramMessage
                        }
                      >
                        <BiSend />
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        ) : null}
      </Row>
    </>
  );
}

export default Chat;
