import { Row, Input, Button, Modal, Table } from "reactstrap";

import { useState, useEffect } from "react";

import SweetAlert from "react-bootstrap-sweetalert";
import { postCasosMaterials,getCasosRead } from "../../helpers/backend";
import styles from "../Contacts/Contacts.module.scss"
const ModalMateriales = (props) => {
  const [descripcion, setDescripcion] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [actionAlert, setActionAlert] = useState(false);
  const [from, setFrom] = useState(false);
  console.log(actionAlert);

  const  [caso, setCaso] = useState ({});
  console.log(caso)
  const getCasoData = async () => {
      const response = await getCasosRead(props.casosId);
      const data = response.data;
      setCaso(data)
  }

   useEffect(() => {
      getCasoData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  const actionSuccess = async () => {
    try {
      setActionAlert(true); // Mostrar que la acción está en progreso

      const response = await postCasosMaterials({
        caso: props.casosId,
        descripcion: descripcion,
        cantidad: cantidad,
        observaciones: observaciones,
      });

      if (response.status === 201) {
        setShowAlertSuccess(true); // Mostrar éxito
      } else {
        setErrorAlert(true); // Mostrar error
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setErrorAlert(true); // Mostrar error en caso de excepción
    } finally {
      setActionAlert(false); // Ocultar el indicador de acción
    }
  };


  return (
    <>
      {showAlertSuccess ? (
        <SweetAlert
          title="Hecho!"
          success
          showConfirm={false}
          timeout={2000}
          onConfirm={() => {
            props.getCasosData();
            setShowAlertSuccess(false);
            props.handleCloseModal();
          }}
        ></SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert
          title="Error!"
          danger
          showConfirm={false}
          timeout={2000}
          onConfirm={() => {
            props.getCasosData();
            setErrorAlert(false);
          }}
        ></SweetAlert>
      ) : null}
      <Modal
        isOpen={props.modalMateriales}
        toggle={() => props.handleCloseModal()}
      >
        <div className="modal-header">
          <h3 className="modal-title mt-2"> Materiales</h3>
          {/* <Link
                          to="#"
                          onClick={() => { assignEncargado() }}>
                          <span className="font-size-16"><i className=" bx bx-lg bx-plus-circle "></i></span>
                        </Link> */}

<Button

style={{position:'relative', right:'122px', top:'-7px'}}
                         title="Agregar Material" 
                           color="link"
                           className={`btn-sm waves-light waves-effect mb-1 ${styles['hover-scale']}`}
                        onClick={(e) =>{
                          e.stopPropagation(); 
                          setFrom(!from)
                          // handleAction('PAUSAR', casos.name, casos.id)}
                        }}
                        >
                          {!from ? 
                          <i className="bx  bx-plus-circle" style={{ color: '#1E90FF', fontSize: '24px' }}></i>
                          : <i className="bx  bx-minus-circle" style={{ color: '#1E90FF', fontSize: '24px' }}></i>
                          
                   

                        
                        }
                         </Button>
          <button
            type="button"
            onClick={() => {
              props.handleCloseModal();
            }}
            className="btn"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {Array.isArray(caso.materiales) && caso.materiales.length !== 0 ? (
            <>  
          <Table
          className="table table-sm  align-middle table-centered table-nowrap mb-3 mt-1"
          >
            <thead className="table-dark">
              <tr>
                <th>Descripcion</th>
                <th>Cantidad</th>
                <th>Observaciones</th>
              </tr>
            </thead>
            <tbody>
              {caso.materiales.map((material) => (
                <tr key={material.id}>
                  <td>{material.descripcion}</td>
                  <td>{material.cantidad}</td>
                  <td>{material.observaciones}</td>
                </tr>
              ))}
            </tbody>
            </Table>
            </>
          ) : null
          
          }

      {from ? ( 
        <>
          <Row className="mb-3 p-2">
          <label htmlFor="titulo" style={{ fontWeight: 'bold', fontSize: '16px' }}>Descripcion</label>
            <Input
              type="text"
              onChange={(e) => setDescripcion(e.target.value)}
              placeholder="Descripcion"
            />
          </Row>

          <Row className="mb-3 p-2">
          <label htmlFor="titulo" style={{ fontWeight: 'bold', fontSize: '16px' }}>Cantidad</label>
            <Input
              type="text"
              onChange={(e) => setCantidad(e.target.value)}
              placeholder="Cantidad"
            />
          </Row>
          <Row className="mb-3 p-2">
          <label htmlFor="titulo" style={{ fontWeight: 'bold', fontSize: '16px' }}>Observaciones</label>
            <Input
              type="text"
              onChange={(e) => setObservaciones(e.target.value)}
              placeholder="Observaciones"
            />
          </Row>
        </>
      ) : null
        }
        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={() => props.handleCloseModal()}>
            Close
          </Button>
          <Button color="primary" onClick={() => actionSuccess()}>
            Save changes
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalMateriales;
