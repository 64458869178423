import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { FaPlus } from 'react-icons/fa'; // Importar iconos
import './Typifications.css'; // Importar los estilos
import { getCampaignList, postTypifications } from '../../helpers/backend';

const Typifications = () => {
  const [data, setData] = useState([]); // Datos iniciales desde el backend
  const [selectedCampania, setSelectedCampania] = useState(null);
  const [selectedCalificacion, setSelectedCalificacion] = useState(null);
  const [newCalificaciones, setNewCalificaciones] = useState([]); // Rastrear nuevas calificaciones

  // Obtener datos iniciales
  const getData = async () => {
    try {
      const response = await getCampaignList();
      const adaptedData = response.data.results.map(campaign => ({
        campaign_id: campaign.id,
        name: campaign.name,
        calificaciones: campaign.calificaciones.map(calificacion => ({
          id: calificacion.id,
          name: calificacion.name,
          descripciones: calificacion.descripciones.map(descripcion => descripcion.name),
          isNew: false // Marcamos las calificaciones existentes como no nuevas
        }))
      }));
      setData(adaptedData);
    } catch (error) {
      console.error("Error al obtener las tipificaciones:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Agregar una nueva calificación
  const handleAddCalificacion = (campaniaId) => {
    const nombreCalificacion = prompt("Ingrese el nombre de la calificación:");
    if (nombreCalificacion) {
      const newCalificacion = {
        id: Date.now(), // Usamos un timestamp como ID temporal
        name: nombreCalificacion,
        descripciones: [],
        isNew: true // Marcamos la calificación como nueva
      };

      const updatedData = data.map(campania =>
        campania.campaign_id === campaniaId
          ? { ...campania, calificaciones: [...campania.calificaciones, newCalificacion] }
          : campania
      );
      setData(updatedData);
      setNewCalificaciones([...newCalificaciones, newCalificacion]); // Agregar a la lista de nuevas calificaciones
    }
  };

  // Agregar una nueva descripción
  const handleAddDescripcion = (campaniaId, calificacionIndex) => {
    const descripcion = prompt("Ingrese la descripción:");
    if (descripcion) {
      const updatedData = data.map(campania =>
        campania.campaign_id === campaniaId
          ? {
              ...campania,
              calificaciones: campania.calificaciones.map((calificacion, index) =>
                index === calificacionIndex
                  ? { ...calificacion, descripciones: [...calificacion.descripciones, descripcion] }
                  : calificacion
              )
            }
          : campania
      );
      setData(updatedData);
    }
  };

  // Guardar cambios (solo datos nuevos)
  const handleSaveChanges = async () => {
    const payload = data
      .map(campania => ({
        campaign_id: campania.campaign_id,
        calificaciones: campania.calificaciones
          .filter(calificacion => calificacion.isNew) // Filtramos solo las calificaciones nuevas
          .map(calificacion => ({
            name: calificacion.name,
            descripciones: calificacion.descripciones
          }))
      }))
      .filter(campania => campania.calificaciones.length > 0); // Filtramos campañas que tengan calificaciones nuevas
    const response = await postTypifications(payload)
      if (response.status === 200 ){
        alert("Datos agregados correctamente.");
      }
      else {
        alert("Error al agregar los datos");
      }
  };

  return (
    <React.Fragment>
      <Container fluid>
        <div className='page-content'>
          <div className="typifications-container">
            {/* Columna de Campañas */}
            <div className="typifications-column">
              <h2>Campañas</h2>
              <ul className="typifications-list">
                {Array.isArray(data) && data.map(campania => (
                  <li
                    key={campania.campaign_id}
                    className={`typifications-card ${selectedCampania === campania.campaign_id ? 'selected' : ''}`}
                    onClick={() => setSelectedCampania(campania.campaign_id)}
                  >
                    {campania.name || `Campaña ${campania.campaign_id}`}
                  </li>
                ))}
              </ul>
            </div>

            {/* Columna de Calificaciones */}
            <div className="typifications-column">
              <h2>Calificaciones</h2>
              {selectedCampania && data.find(c => c.campaign_id === selectedCampania)?.calificaciones?.map((calificacion, index) => (
                <div
                  key={calificacion.id}
                  className={`typifications-card ${selectedCalificacion === index ? 'selected' : ''}`}
                  onClick={() => setSelectedCalificacion(index)}
                >
                  <div className="typifications-connector">{calificacion.name}</div>
                  <button
                    className="typifications-button add-descripcion"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddDescripcion(selectedCampania, index);
                    }}
                  >
                    <FaPlus className="typifications-button-icon" />
                    Agregar Descripción
                  </button>
                </div>
              ))}
              {selectedCampania && (
                <button className="typifications-button" onClick={() => handleAddCalificacion(selectedCampania)}>
                  <FaPlus className="typifications-button-icon" />
                  Agregar Calificación
                </button>
              )}
            </div>

            {/* Columna de Descripciones */}
            <div className="typifications-column">
              <h2>Descripciones</h2>
              {selectedCalificacion !== null && data.find(c => c.campaign_id === selectedCampania)?.calificaciones[selectedCalificacion]?.descripciones?.map(
                (descripcion, index) => (
                  <div key={index} className="typifications-card">
                    <div className="typifications-connector">{descripcion}</div>
                  </div>
                )
              )}
            </div>
          </div>
          {/* Botón de Guardar Cambios */}
          <button className="typifications-button save-changes" onClick={handleSaveChanges}>
            Guardar Cambios
          </button>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Typifications;