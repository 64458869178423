import React, { useState } from "react";
import { Modal, Alert } from "reactstrap";
import "../Chat.css";
import { BiCheck, BiCheckDouble } from "react-icons/bi";

import {
  Col,
  Row,
  Button,
  ModalBody,
} from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css'; // Asegúrate de importar los estilos de Bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileWord, faFile } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FaPhone, FaUser, FaWhatsapp } from "react-icons/fa";

function ModalManagementDetails(props) {
  const URL_LARGE = process.env.REACT_APP_API_URL;
  const url = URL_LARGE.split('/api');
  const history = useHistory()
  const handleButtonClick = () => {
    props.onCloseClick();
  };
  const [imageModal, setImageModal] = useState('')
  const [imageOpen, setImageOpen] = useState(null);


  function getDocumentIcon(documentPath) {
    const extension = documentPath.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return <FontAwesomeIcon icon={faFilePdf} className="document-icon mt-1" style={{ fontSize: '35px' }} />;
      case 'doc':
      case 'docx':
        return <FontAwesomeIcon icon={faFileWord} className="document-icon mt-1" style={{ size: '15px' }} />;
      default:
        return <FontAwesomeIcon icon={faFile} className="document-icon mt-1" style={{ size: '15px' }} />;
    }
  }
  console.log(props);
  return (
    <div>
      <Modal
        isOpen={imageOpen}
        toggle={() => { setImageOpen(false); setImageModal("") }}
        size='xl'
      >
        <div >
          <div className='d-flex justify-content-center p-2' >
            <div >
              <img className='max-size-img-modal' alt='Imagen' src={imageModal} />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={props.isOpen}
        toggle={props.onCloseClick}
        centered={true}
        size="lg"
      >
        <ModalBody>
          <Row>
            <h5 className="text-primary"><b>Gestion {props.GestionDetallada.id}</b>
            {props.GestionDetallada.isLead && <FaUser className="ms-2 text-warning" title="Lead" />}</h5>

          </Row>
          <Row>
              <Col className="p-2 bg-secondary text-muted  font-size-18">
                <b>{props.GestionDetallada.contacto}</b>
              </Col>
            </Row>
          <Row>
            {props.GestionDetallada.type !== "WhatsApp" && (
              <Col className="p-2 bg-primary text-white text-center d-flex align-items-center justify-content-center font-size-16">
                <FaPhone className="me-2" /> <b>Gestión Telefónica</b> 
              </Col>
            )}
            {props.GestionDetallada.type === "WhatsApp" && (
              <Col className="p-2 bg-success text-white text-center d-flex align-items-center justify-content-center font-size-16">
                <FaWhatsapp className="me-2" /> <b>WhatsApp</b>
              </Col>
            )}
          </Row>
          <Row style={{ fontSize: '14px' }}>
            <Row>
              <Col>
                <dt>Fecha Gestión</dt>
                <dd>
                  {" "}
                  {props.GestionDetallada.created_date}
                </dd>
              </Col>
              <Col >
                <dt>Agente</dt>
                <dd>{props.GestionDetallada.agent ? props.GestionDetallada.agent[0] : null} </dd>
              </Col>
              <Col >
                <dt>Campaña</dt>
                <dd>
                  {props.GestionDetallada.campaign[0] && props.GestionDetallada.campaign.map(item => item[0])}
                </dd>
              </Col>
            </Row>
            <Row>
              <Col >
                <dt>Medio de Gestión</dt>
                <dd>
                  {props.GestionDetallada.type}
                </dd>
              </Col>
              <Col >
                <dt>Resultados</dt>
                <dd>
                  {props.GestionDetallada.calificacion}
                </dd>
              </Col>
              <Col className="md-6">
                <dt>Calificación</dt>
                <dd>{props.GestionDetallada.descripcion} </dd>
              </Col>
            </Row>
            <Row>
              <Col className="md-12">
                <dt>Duración de Gestión</dt>
                <dd>
                  {props.GestionDetallada.duration_gestion}
                </dd>
              </Col>
              <Col className="md-6">
                <dt>Estado de Gestión</dt>
                {props.GestionDetallada.estado === "FINALIZADO" ? (
                  <span className="badge bg-success">
                    Finalizado
                  </span>
                ) : props.GestionDetallada.estado === "VENCIDO" ? (
                  <span className="badge bg-danger">
                    Vencido
                  </span>
                ) : props.GestionDetallada.estado === "EN_GESTION" ? (
                  <span
                    className="badge "
                    style={{
                      background: "rgb(111, 66, 193)",
                    }}
                  >
                    En Gestión
                  </span>
                ) : null}
              </Col>
              <Col className="md-12">
                <dt>Duración de Asignación</dt>
                <dd>
                  {props.GestionDetallada.duration_asignacion}
                </dd>
              </Col>
            </Row>
            <Row>
              <Col className="md-6">
                <dt>Duración Total</dt>
                <dd>{props.GestionDetallada.total_duration} </dd>
              </Col>
              <Col className="md-6">
                <dt>Fecha de Finalización</dt>
                <dd>{props.GestionDetallada.fecha_finalizacion} </dd>
              </Col>
              <Col className="md-12">
                <dt>Observaciones</dt>
                <dd>
                  {props.GestionDetallada.observaciones}
                </dd>
              </Col>
            </Row>
          </Row>
          {props.GestionDetallada.type === "WhatsApp" && (
          <Row>
            <div className="accordion mt-4" id="accordionExample">
              <div className="accordion-item">

                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    Ver Mensajes
                  </button>
                </h2>

                <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body" style={{ maxHeight: '300px', display: 'flex' }}>
                    <div className={props.GestionDetallada.type === "Telegram" ? "message-container-telegram" : "message-container-light"}>
                      {Array.isArray(props.GestionDetallada.historial_conversation)
                        ? props.GestionDetallada.historial_conversation.map((message, messageIndex) => {
                          return (
                            <div key={messageIndex}>
                              {message.msg_type === 'nota' ? (
                                <Col lg={12} className='d-flex justify-content-end mt-2'>
                                  <Alert color="info" role="alert" className='mb-0 p-2' style={{ fontSize: 'smaller' }}>
                                    <div><b>{message.contacto}</b></div>
                                    <div>{message.body}</div>
                                  </Alert>
                                </Col>
                              ) : message.msg_type === 'image' ? (
                                <div className={message.role === 'From_me' ? 'sent-message-light' : 'received-message-light'}>
                                  {message.role === 'From_me' ? (
                                    <div className="agent-name-light"><b>{message.contacto}</b></div>
                                  ) : null}
                                  <div className="message-content mt-2">
                                    <img className="max-size-img" src={url[0] + message.file} alt="Imagen" onClick={() => { setImageOpen(true); setImageModal(url[0] + message.file) }} />
                                  </div>
                                  <div className="message-text mt-2">{message.caption}</div>
                                  <div className='mt-2'>
                                    <div className="message-time-light" >{message.time}</div>
                                    {message.role === 'From_me' && message.status === 'read' && (
                                      <BiCheckDouble className="checkIcon-message read-icon" />
                                    )}
                                    {message.role === 'From_me' && message.status === 'sent' && (
                                      <BiCheck className="checkIcon-message delivered-icon" />
                                    )}
                                    {message.role === 'From_me' && message.status === 'delivered' && (
                                      <BiCheckDouble className="checkIcon-message" />
                                    )}
                                  </div>
                                </div>
                              ) : message.msg_type === 'audio' ? (
                                <div className={message.role === 'From_me' ? 'sent-message-light' : 'received-message-light'}>
                                  {message.role === 'From_me' ? (
                                    <div className="agent-name-light" ><b>{message.contacto}</b></div>
                                  ) : null}
                                  <div className="message-content mt-2">
                                    <audio controls>
                                      <source src={url[0] + message.file} type="audio/mpeg" />
                                      Your browser does not support the audio element.
                                    </audio>
                                  </div>
                                  <div className="message-text mt-1">{message.caption}</div>
                                  <div className='mt-1'>
                                    <div className="message-time-light">{message.time}</div>
                                    {message.role === 'From_me' && message.status === 'read' && (
                                      <BiCheckDouble className="checkIcon-message read-icon" />
                                    )}
                                    {message.role === 'From_me' && message.status === 'sent' && (
                                      <BiCheck className="checkIcon-message delivered-icon" />
                                    )}
                                    {message.role === 'From_me' && message.status === 'delivered' && (
                                      <BiCheckDouble className="checkIcon-message" />
                                    )}
                                  </div>
                                </div>
                              ) : message.msg_type === 'video' ? (
                                <div className={message.role === 'From_me' ? 'sent-message-light' : 'received-message-light'}>
                                  {message.role === 'From_me' ? (
                                    <div className="agent-name-light" ><b>{message.contact}</b></div>
                                  ) : null}
                                  <div className="message-content mt-2">
                                    <video controls className='max-size-img' >
                                      <source src={url[0] + message.file} type="video/mp4" />
                                      Your browser does not support the video element.
                                    </video>
                                  </div>
                                  <div className="message-text mt'1">{message.caption}</div>
                                  <div className='mt-2'>
                                    <div className="message-time-light">{message.time}</div>
                                    {message.role === 'From_me' && message.status === 'read' && (
                                      <BiCheckDouble className="checkIcon-message read-icon" />
                                    )}
                                    {message.role === 'From_me' && message.status === 'sent' && (
                                      <BiCheck className="checkIcon-message delivered-icon" />
                                    )}
                                    {message.role === 'From_me' && message.status === 'delivered' && (
                                      <BiCheckDouble className="checkIcon-message" />
                                    )}
                                  </div>
                                </div>
                              ) : message.msg_type === 'document' ? (
                                <div className={message.role === 'From_me' ? 'sent-message-light' : 'received-message-light'}>
                                  {message.role === 'From_me' ? (
                                    <div className="agent-name-light" ><b>{message.contacto}</b></div>
                                  ) : null}
                                  <div className="message-content mt-2">
                                    <a href={url[0] + message.file} target="_blank" rel="noopener noreferrer" className='me-1 text-black'>
                                      {getDocumentIcon(message.file)}
                                      {message.filename}
                                    </a>
                                  </div>
                                  <div className="message-text mt-1">{message.caption}</div>
                                  <div className='mt-2'>
                                    <div className="message-time-light">{message.time}</div>
                                    {message.role === 'From_me' && message.status === 'read' && (
                                      <BiCheckDouble className="checkIcon-message read-icon" />
                                    )}
                                    {message.role === 'From_me' && message.status === 'sent' && (
                                      <BiCheck className="checkIcon-message delivered-icon" />
                                    )}
                                    {message.role === 'From_me' && message.status === 'delivered' && (
                                      <BiCheckDouble className="checkIcon-message" />
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div className={message.role === 'From_me' ? 'sent-message-light' : 'received-message-light'}>
                                  {message.role === 'From_me' ? (
                                    <div className="agent-name-light" ><b>{message.contacto}</b></div>
                                  ) : null}
                                  <div className="message-content">
                                    <div className="message-text">{message.body}</div>
                                  </div>
                                  <div className='mt-2'>
                                    <div className="message-time-light">{message.time}</div>
                                    {message.role === 'From_me' && message.status === 'read' ?
                                      <BiCheckDouble className="checkIcon-message read-icon" />
                                      :
                                      message.role === 'From_me' && message.status === 'sent' ?
                                        <BiCheck className="checkIcon-message delivered-icon" />
                                        :
                                        message.role === 'From_me' && message.status === 'delivered' ?
                                          <BiCheckDouble className="checkIcon-message" />
                                          : null}
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })
                        : []}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          )}
          <Row className="mt-3">
            <Col className="d-flex justify-content-end">
              <Button className=" btn btn-success me-1"
                onClick={() => {
                  window.focus();
                  history.replace({
                    pathname: '/chat',
                    state: {
                      selectedChatId: props.GestionDetallada.conversation && props.GestionDetallada.conversation,
                      contactId: props.GestionDetallada.contacto_id && props.GestionDetallada.contacto_id, type: "WhatsApp",
                    }
                  });

                }}>
                   <FaWhatsapp className="font-size-20 me-2" />
                Ir al Chat
              </Button>
              <Button className="text-dark btn btn-secondary"
                onClick={() => {
                  handleButtonClick()
                }}
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ModalManagementDetails;
