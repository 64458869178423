// src/Agendamiento.js
import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-modal';

// Configuración de moment y el localizador
const localizer = momentLocalizer(moment);

// Estilos para el modal
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

const Schedule = () => {
  const [events, setEvents] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: '',
    start: '',
    end: '',
  });

  const handleSelectSlot = ({ start, end }) => {
    setNewEvent({ title: '', start, end });
    setModalIsOpen(true);
  };

  const handleSaveEvent = () => {
    setEvents([...events, newEvent]);
    setModalIsOpen(false);
    setNewEvent({ title: '', start: '', end: '' });
  };

  return (
    <div className='page-content' style={{ height: '500px', margin: '50px' }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        selectable
        onSelectSlot={handleSelectSlot}
        defaultView="agenda"
        language="es"
      />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        contentLabel="Agregar Evento"
      >
        <h2>Agregar Evento</h2>
        <input
          type="text"
          placeholder="Título del evento"
          value={newEvent.title}
          onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
        />
        <button onClick={handleSaveEvent}>Guardar</button>
        <button onClick={() => setModalIsOpen(false)}>Cancelar</button>
      </Modal>
    </div>
  );
};

export default Schedule;