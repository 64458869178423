import React, { useEffect, useState } from 'react';  
import { Card, Table, Button, Input, Row, Col, CardBody, Alert } from 'reactstrap';  
import "../Chat.css";  
import Select from "react-select";
import { getCampaignFullList, getWhatsappAtajos, postWhatsappAtajos } from '../../../helpers/backend';  

const ManagementHistory = ({ updateHistoryOpen }) => {  
  const user = JSON.parse(localStorage.getItem("user"))
  const groups =String(user.groups);

  const [data, setData] = useState([]);  
  const [newAtajo, setNewAtajo] = useState('');  
  const [newText, setNewText] = useState('');  
  const [newCampaign, setNewCampaign] = useState([]);  
  const [feedback, setFeedback] = useState(null);

  const getAtajosData = async () => {   
    const response = await getWhatsappAtajos();  
    setData(response.data);   
  };   
  const [campaignData, setCampaignData] = useState([]);
  const getCampaignData = async () => {
    const response = await getCampaignFullList();
    setCampaignData(response.data);
  };
  useEffect(() => {  
    getAtajosData();  
    getCampaignData()
  }, []);   

  const handleHistoryButtonClick = () => {  
    updateHistoryOpen(false);  
  };  

  const handleAddAtajo = async () => {  
    const formattedAtajo = `/${newAtajo.toUpperCase()}`;  
    await postWhatsappAtajos({  
      atajo: formattedAtajo,  
      text: newText,
      campaign: newCampaign,  
    });
    
    setFeedback('Atajo agregado exitosamente');
    getAtajosData();  
    setNewAtajo("");  
    setNewText("");  

    setTimeout(() => setFeedback(null), 3000);  // Limpiar el mensaje después de 3 segundos
  };  
console.log(newCampaign);
  return (  
    <>  
      <Card style={{ borderTop: '5px solid #0e685f', borderRadius: '10px', backgroundColor: '#f8f9fa' }}>  
        <CardBody className="management-container">  
          <div className="d-flex justify-content-between align-items-center">
            <h5 className='p-1 mb-0'><b>Personalizar Atajos</b></h5>  
            <span className='window-close' onClick={handleHistoryButtonClick}>  
              <i className='fas fa-times fa-lg'></i>  
            </span>  
          </div>
          <hr />

          {feedback && <Alert color="success">{feedback}</Alert>}

          <div className='px-3 mx-3'>  
            <p className='text-primary'><b>Añadir nuevo atajo</b></p>  
            {groups.includes("SuperAdmin") ||  groups.includes("Administrador") || groups.includes("Supervisor") ? (
            <Select
              options={campaignData.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              onChange={(item) => {
                setNewCampaign([item.value]);
              }}
              placeholder={"Seleccione"}
              isMulti={false}
              menuPosition="fixed"
            />
            ):null}
            <Input   
              type="text"   
              placeholder="Escribe el nombre del atajo" 
              className='mt-2'  
              value={newAtajo}   
              onChange={e => setNewAtajo(e.target.value)}   
              style={{ borderRadius: '8px' }}  
            />  
            <Input   
              type="textarea"   
              placeholder="Escribe el texto"  
              className='mt-2'  
              value={newText}   
              onChange={e => setNewText(e.target.value)}   
              style={{ borderRadius: '8px' }}  
            />
            <Row className="justify-content-center mt-3">
              <Col md={4}>
                <Button color="primary" onClick={handleAddAtajo} block style={{ borderRadius: '8px' }}>
                  <i className="fas fa-plus-circle"></i> Agregar 
                </Button>
              </Col>
            </Row>
          </div>  

          <Row className="mt-4"> 
            <Col className="mx-5"> 
              <Table bordered hover responsive className='text-center'>  
                <thead className='thead-dark'>  
                  <tr>  
                    <th>Atajo</th>  
                    <th>Texto</th>  
                  </tr>  
                </thead>  
                <tbody className='font-size-12'>  
                  {Array.isArray(data) ? data.map((item, index) => (  
                    <tr key={index}>  
                      <td ><b>{item.atajo}</b></td>  
                      <td>{item.text}</td>  
                    </tr>  
                  )) : <tr><td colSpan="2">No hay atajos disponibles</td></tr>}  
                </tbody>  
              </Table>  
            </Col>
          </Row>
        </CardBody>  
      </Card>  
    </>  
  );  
};  

export default ManagementHistory;
