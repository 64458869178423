import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import MetisMenu from "metismenujs";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import logo3 from "../../assets/images/logo-symbol-tc.png";
//import { getModules } from "../../helpers/backend";

const SidebarContent = (props) => {
  const ref = useRef();
  const user = JSON.parse(localStorage.getItem("user"));
  const groups = String(user.groups);


 /* const [activeModules, setActiveModules] = useState([]);
  const getDataModule = async () => {
    const response = await getModules();
    // Filtrar módulos activos
    const active = response.data.results.filter(module => module.active);
    setActiveModules(active);
  };

  useEffect(() => {
    getDataModule();
  }, []);
*/
  useEffect(() => {
    const initMenu = () => {
      new MetisMenu("#side-menu");
    };
    initMenu();
  }, []);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  // Función para verificar si un módulo está activo
 /* const isModuleActive = (moduleName) => {
    return activeModules.some(module => module.name === moduleName);
  };
*/
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            <li>
              <Link to="/dashboard">
                <i className="bx bxs-dashboard"></i>
                <span key="t-dashboard">Dashboard</span>
              </Link>
            </li>

            {(groups.includes("SuperAdmin")) /*&& isModuleActive("User")*/ ? (
                <li>
                  <Link to="#">
                    <i className="fas fa-user"></i>
                    <span key="t-users">Administración</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/users">Usuarios</Link>
                    </li>
                    <li>
                      <Link to="/modules">Módulos</Link>
                    </li>
                    <li>
                      <Link to="/rol/add_rol">Roles</Link>
                    </li>
                  </ul>
                </li>
              ) : null}
   {(groups.includes("SuperAdmin") || groups.includes("Administrador") || groups.includes("Supervisor")) /*&& isModuleActive("Campañas")*/ ? (

                <li>
                  <Link to="/campaign">
                    <i className="fas fa-flag"></i>
                    <span key="t-Parametrizaciones">Campañas</span>
                  </Link>
                </li>
             ) : null}


            {/*isModuleActive("Contactos") ? (*/
              <li>
                <Link to="/contacts">
                  <i className="fas fa-user-tie"></i>
                  <span key="t-contacts">Contactos</span>
                </Link>
              </li>
           /* ) : null*/}

            {/*isModuleActive("Leads") ? (*/
              <li>
                <Link to="/leads">
                  <i className="fas fa-users"></i>
                  <span key="t-Parametrizaciones">Leads</span>
                </Link>
              </li>
            /*) : null*/ }

            {/*isModuleActive("TrueContact") ? (*/
              <li>
                <Link to="/chat">
                  <img src={logo3} alt="" height="25" className="ms-1 me-1" />
                  <span key="t-canales">TrueContact</span>
                </Link>
              </li>
            /*) : null*/}

            {/*isModuleActive("Casos") ? (*/
              <li>
                <Link to="/casos">
                  <i className="fas fa-ticket-alt"></i>
                  <span key="t-casos">Casos</span>
                </Link>
              </li>
           /* ) : null*/}

            {(groups.includes("SuperAdmin") || groups.includes("Administrador"))/* && isModuleActive("Tareas")*/ ? (
              <li>
                <Link to={`/project`}>
                  <i className="fas fa-tasks"></i>
                  <span key="t-survey">Tareas</span>
                </Link>
              </li>
            ) : null}

            {(groups.includes("SuperAdmin") || groups.includes("Administrador"))/* && isModuleActive("Encuestas")*/ ? (
              <li>
                <Link to={`/survey`}>
                  <i className="fas fa-question-circle fa-lg"></i>
                  <span key="t-survey">Encuestas</span>
                </Link>
              </li>
            ) : null}

            {(groups.includes("SuperAdmin") || groups.includes("Administrador"))/* && isModuleActive("Calendario")*/ ? (
              <li>
                <Link to={`/schedule`}>
                  <i className="fas fa-calendar-alt fa-lg ms-2"></i>
                  <span key="t-calendar">Agenda</span>
                </Link>
              </li>
            ) : null}

            {(groups.includes("SuperAdmin") || groups.includes("Administrador")) /*&& isModuleActive("Tienda")*/ ? (
              <li>
                <Link to="/audits">
                  <i className="fas fa-store fa-md"></i>
                  <span key="t-audits">Tienda</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/productos">Productos</Link>
                  </li>
                </ul>
              </li>
            ) : null}

            {(groups.includes("SuperAdmin") || groups.includes("Administrador"))/* && isModuleActive("Auditoria")*/ ? (
              <li>
                <Link to="/audits">
                  <i className="fas fa-clipboard-check fa-lg ms-2"></i>
                  <span key="t-audits">Auditoría</span>
                </Link>
              </li>
            ) : null}

            {(groups.includes("SuperAdmin") || groups.includes("Administrador") || groups.includes("Supervisor")) /*&& isModuleActive("Reportes")*/ ? (
              <li>
                <Link to="#">
                  <i className="fas fa-file-alt"></i>
                  <span key="t-file">Reportes</span>
                </Link>
                <ul className="sub-menu">
                  <li><Link to="/reports">Reportes</Link></li>
                  <li><Link to="/managementList">Lista de Gestiones</Link></li>
                  <li><Link to="/issabelReport">Reportes Issabel</Link></li>
                  <li><Link to="/report_supervision">Reporte de Supervisión</Link></li>
                </ul>
              </li>
            ) : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
};

export default withRouter(withTranslation()(SidebarContent));