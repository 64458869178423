import PropTypes from "prop-types";
import React, { useState } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import { FaAddressBook } from 'react-icons/fa'; // Asegúrate de instalar react-icons si no lo tienes
import ContactDirectory from '../ContactDirectory'; // Importa el componente que contiene el contenido del Drawer

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logo2 from '../../assets/images/logo-tc.png'
import logo3 from '../../assets/images/logo-symbol-tc.png'

// Redux Store
import {
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import PhonePanel from "../PhonePanel";
import SessionTimer from "../CommonForBoth/TopbarDropdown/SessionTimer";

const Header = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    /*
      set logic for changing sidebar
    */
    if (document.body.clientWidth >= 993) {
      //desktop view
      if (props.leftSideBarType === "default") {
        props.changeSidebarType("small");
      } else if (props.leftSideBarType === "small" || props.leftSideBarType === "compact") {
        props.changeSidebarType("default");
      }
    } else {
      //mobile view
      document.body.classList.toggle("sidebar-enable");
      props.changeSidebarType("default");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo ">
                <span className="logo-sm">
                  <img src={logo3} alt="" height="30" />
                </span>
                <span className="logo-lg">
                  <img src={logo2} alt="" height="50" width="150" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
          <SessionTimer />
            <NotificationDropdown />
            <ProfileMenu />

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen" />
              </button>
            </div>
            <div
        style={{
          position: 'fixed',
          top: '60px',
          right: drawerOpen ? '-9px' : '-305px', // Se mueve todo junto
          width: '300px', // Ancho del contenedor para el botón y el Drawer
          height: '100%', // Ocupa toda la altura
          backgroundColor: '#f8f9fa', // Fondo del Drawer
          transition: 'right 0.3s ease', // Animación suave
          boxShadow: drawerOpen ? '-5px 0 10px rgba(0,0,0,0.1)' : 'none', // Sombra cuando está abierto
          zIndex: '1000',
        }}
      >
        {/* Pestaña Flotante */}
        <div
          onClick={toggleDrawer}
          style={{
            position: 'absolute',
            top: '60px',
            left: '-50px', // Posiciona el botón fuera del contenedor
            width: '45px',
            height: '40px',
            backgroundColor: 'rgb(111, 66, 193)', // Color del botón
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px 0 0 5px', // Bordes redondeados
            cursor: 'pointer',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          }}
        >
          <FaAddressBook style={{ fontSize: '20px' }} />
        </div>

        {/* Drawer Content */}
        <ReactDrawer
          open={drawerOpen}
          onClose={toggleDrawer}
          direction="right"
          className="custom-drawer"
        >
          <ContactDirectory />
        </ReactDrawer>
        <PhonePanel />
      </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  toggleLeftmenu,
  changeSidebarType,
})(Header);
