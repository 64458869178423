import React, { useEffect, useState } from 'react';
import { getModules, patchModules, patchSubmodules } from '../../../helpers/backend';
import { Container } from 'reactstrap';
import './ModuleList.css'; // Importamos los estilos CSS
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ModuleList = () => {
  const history = useHistory();
  const [modules, setModules] = useState([]);
  const [expandedModuleId, setExpandedModuleId] = useState(null); // Para manejar el acordeón

  const getData = async () => {
    const response = await getModules();
    setModules(response.data.results);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleModuleToggle = async (moduleId) => {
    setModules((prevModules) =>
      prevModules.map((module) =>
        module.id === moduleId
          ? { ...module, active: !module.active }
          : module
      )
    );

    // Encuentra el módulo que se está actualizando
    const moduleToUpdate = modules.find(module => module.id === moduleId);
    if (moduleToUpdate) {
      // Actualiza el módulo en el backend
      await updateModules(moduleId, !moduleToUpdate.active);
    }
  };

  const handleSubmoduleToggle = async (moduleId, submoduleId) => {
    setModules((prevModules) =>
      prevModules.map((module) =>
        module.id === moduleId
          ? {
              ...module,
              submodulos: module.submodulos.map((submodule) =>
                submodule.id === submoduleId
                  ? { ...submodule, active: !submodule.active }
                  : submodule
              ),
            }
          : module
      )
    );

    // Encuentra el submódulo que se está actualizando
    const moduleToUpdate = modules.find(module => module.id === moduleId);
    if (moduleToUpdate) {
      const submoduleToUpdate = moduleToUpdate.submodulos.find(submodule => submodule.id === submoduleId);
      if (submoduleToUpdate) {
        // Actualiza el submódulo en el backend
        await updateSubmodules(submoduleId, !submoduleToUpdate.active);
      }
    }
  };

  const toggleAccordion = (moduleId) => {
    setExpandedModuleId((prevId) => (prevId === moduleId ? null : moduleId));
  };

  const handleSaveChanges = () => {
    alert('Para ver los cambios hay que refrescar el aplicativo');
  
      history.push('/dashboard'); 
      window.location.reload();
  };

  const updateModules = async (moduleId, active) => {
    try {
      const response = await patchModules(moduleId, { active });
      console.log('Módulo actualizado:', response.data);
    } catch (error) {
      console.error('Error al actualizar el módulo:', error);
    }
  };

  const updateSubmodules = async (submoduleId, active) => {
    try {
      const response = await patchSubmodules(submoduleId, { active });
      console.log('Submódulo actualizado:', response.data);
    } catch (error) {
      console.error('Error al actualizar el submódulo:', error);
    }
  };

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page-content">
          <div className="module-list">
            <div className="header-with-button">
              <h3>Módulos y Submódulos</h3>
              <button className="save-button" onClick={handleSaveChanges}>
                Guardar Cambios
              </button>
            </div>
            <div className="module-grid"> {/* Contenedor de dos columnas */}
              {Array.isArray(modules) ? (
                modules.map((module) => (
                  <div key={module.id} className="module-card">
                    <div className="module-header" onClick={() => toggleAccordion(module.id)}>
                      <label className="module-switch">
                        <input
                          type="checkbox"
                          checked={module.active}
                          onChange={() => handleModuleToggle(module.id)}
                          className="switch-input"
                        />
                        <span className="switch-slider"></span>
                      </label>
                      <span className="module-name">{module.name}</span>
                    </div>
                    {expandedModuleId === module.id && (
                      <div className="submodule-list">
                        {module.submodulos.map((submodule) => (
                          <label key={submodule.id} className="submodule-label">
                            <input
                              type="checkbox"
                              checked={module.active && submodule.active}
                              onChange={() =>
                                handleSubmoduleToggle(module.id, submodule.id)
                              }
                              disabled={!module.active}
                              className="submodule-checkbox"
                            />
                            <span className="submodule-name">{submodule.name}</span>
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p>No hay módulos disponibles.</p>
              )}
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default ModuleList;